import React, { useState } from "react";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { images } from "../../assets/images";
import "./index.css";
import SimpleButton from "../../components/simpleButton";
import usePostQuery from "../../hooks/postQuery.hook";
import apiUrls from "../../apis/apis";
import LoadingSpinner from "../../components/loader";
import { useDispatch } from "react-redux";
import { SET_USER } from "../../redux/actions";
import InputField from "../../components/Input";
import PasswordInput from "../../components/passwordInput";

const CreatePassword = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [otp, setOTP] = useState("");
  const { postQuery } = usePostQuery();
  const dispatch = useDispatch();

  const handleLogin = async (e) => {
    e.preventDefault();
    console.log("alkhclknsd");
    if (password !== confirmpassword) {
      return toast.error("Password doesn't match!");
    }

    if (state?.phone_number && otp) {
      setLoading(true);
      postQuery({
        url: apiUrls.verifyOtp,
        postData: {
          password: password,
          mobile_number: state?.phone_number,
          otp: otp,
        },
        onSuccess: (data) => {
          setLoading(false);
          localStorage.setItem(
            "userDetails",
            JSON.stringify({
              _id: data?.user?._id,
              family_id: data?.user?.family_id,
              token: data?.token,
              user: data?.user,
            })
          );
          dispatch({
            type: SET_USER,
            payload: {
              _id: data?.user?._id,
              family_id: data?.user?.family_id,
              token: data?.token,
              user: data?.user,
            },
          });
          if (data?.user?.mobile_number === "1111111111") {
            localStorage.removeItem("pageIndex");
            navigate("/user");
          } else {
            navigate("/events");
          }
        },
        onFail: (error) => {
          setLoading(false);
          toast.error("Invalid Otp");
          console.log(error, "error");
        },
      });
      setLoading(false);
    }
  };

  return (
    <>
      <div className="main-container bg-[#515fed]">
        <LoadingSpinner loading={loading} />
        <div className="flex items-center justify-center h-screen">
          <div className="md:w-[60%] w-[90%]   bg-[#d3d3d3] grid md:grid-cols-2 z-10 rounded">
            <div className="flex justify-center items-center w-full md:border-r-[1px] border-gray-400 py-3">
              <div className="px-10">
                <div className="mb-3 ">
                  <h1 className="my-3 text-4xl font-bold text-[#3C405E]">
                    Create Password
                  </h1>
                  <p className="text-sm text-[#3C405E]">
                    Sign in to your account
                  </p>
                </div>
                <form onSubmit={handleLogin} className="space-y-12">
                  <div className="space-y-4">
                    <PasswordInput
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <PasswordInput
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      label="Confirm Password"
                    />
                    <InputField
                      title="Enter OTP"
                      inputProps={{
                        type: "text",
                        autoComplete: "off",
                        maxlength: "4",
                      }}
                      onChange={(e) => setOTP(e.target.value)}
                    />
                    <div className="mt-3 flex justify-center">
                      <SimpleButton
                        style={{ width: "120px" }}
                        type="submit"
                        title="Submit"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="p-10 hidden md:flex justify-center items-center flex-col">
              <img
                style={{
                  width: "20rem",
                  height: "6rem",
                }}
                src={images.transparentLogo}
                alt="Logo"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreatePassword;
