import React, { useRef, useState, useEffect } from "react";
import SignatureCanvas from "react-signature-canvas";
import styles from "./index.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import usePostQuery from "../../hooks/postQuery.hook";
import LoadingSpinner from "../loader";
import { toast } from "react-toastify";
import SimpleButton from "../simpleButton";
import apiUrls from "../../apis/apis";

const SignatureComponent = ({
  setNominationDetails,
  nominationDetails,
  nomination_id,
  user_id,
}) => {
  const signatureRef = useRef();
  const [savedSignature, setSavedSignature] = useState(null);
  const { postQuery, loading } = usePostQuery();
  const navigate = useNavigate();

  const clearSignature = (e) => {
    e.preventDefault();
    if (signatureRef.current) {
      signatureRef.current.clear();
    }
    setNominationDetails({
      ...nominationDetails,
      signature_url: "",
    });
    setSavedSignature(null);
  };

  const uploadSignature = async () => {
    console.log("hello upload function");
    if (savedSignature) {
      await postQuery({
        url: apiUrls.uploadSignature,
        postData: { image: savedSignature },
        onSuccess: (result) => {
          setNominationDetails({
            ...nominationDetails,
            signature_url: result.imageUrl,
          });

          if (nomination_id) {
            const { user_id, ...rest } = nominationDetails;
            postQuery({
              url: apiUrls.updateNomination,
              postData: {
                ...rest,
                signature_url: result.imageUrl,
                nomination_id,
              },
              onSuccess: () => {
                navigate("/");
              },
              onFail: () => {},
            });
          } else {
            postQuery({
              url: apiUrls.createNomination,
              postData: {
                ...nominationDetails,
                signature_url: result.imageUrl,
                user_id,
              },
              onSuccess: () => {
                navigate("/");
              },
              onFail: () => {},
            });
          }
        },
        onFail: (err) => {},
      });
    } else {
      if (nomination_id) {
        const { user_id, ...rest } = nominationDetails;
        postQuery({
          url: apiUrls.updateNomination,
          postData: {
            ...rest,
            nomination_id,
          },
          onSuccess: () => {
            navigate("/");
          },
          onFail: () => {},
        });
      } else {
        if (!nominationDetails?.signature_url) {
          toast.error("Something went wrong");
          return;
        }
        postQuery({
          url: apiUrls.createNomination,
          postData: {
            ...nominationDetails,
            user_id,
          },
          onSuccess: () => {
            navigate("/");
          },
          onFail: () => {},
        });
      }
    }
  };

  useEffect(() => {
    if (savedSignature) {
      uploadSignature();
    }
  }, [savedSignature]);

  const saveSignature = (e) => {
    e.preventDefault();
    if (!nominationDetails.signature_url) {
      if (signatureRef.current?.isEmpty()) {
        toast.error("Signature is required");
      } else {
        const signatureDataURL = signatureRef.current.toDataURL();
        setSavedSignature(signatureDataURL);
      }
    } else {
      uploadSignature();
    }
  };

  return (
    <>
      <div className={styles.signatureContainer}>
        <LoadingSpinner loading={loading} />
        <div className={styles.innerContainer}>
          <div className="subHeading">Signature</div>
          {nominationDetails.signature_url ? (
            <img src={nominationDetails.signature_url} alt="Saved Signature" />
          ) : (
            <SignatureCanvas
              backgroundColor="#D3D3D3"
              ref={signatureRef}
              canvasProps={{
                width: 350,
                height: 150,
                className: styles.signaturePad,
              }}
            />
          )}
          <button className={styles.sigBtn} onClick={clearSignature}>
            Clear
          </button>
        </div>
      </div>

      <div className={styles.buttonContainer}>
        <SimpleButton title="Save" onClick={saveSignature} />
      </div>
    </>
  );
};

export default SignatureComponent;
