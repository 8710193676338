import React from 'react';
import SimpleButton from '../../components/simpleButton';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../components/header';
import usePostQuery from '../../hooks/postQuery.hook';
import apiUrls from '../../apis/apis';
import { toast } from 'react-toastify';
import Button from '../../components/buttton';

const ViewButtons = () => {
  const { state } = useLocation();
  const { postQuery, loading } = usePostQuery();
  const navigate = useNavigate();

  const onWidthdraw = async () => {
    await postQuery({
      url: apiUrls.updateNomination,
      postData: { nomination_id: state.data._id, status: 'withdrawn' },
      onSuccess: () => {
        navigate('/');
      },
      onFail: () => {
        toast.error('Something went wrong');
      },
    });
  };

  const onEdit = () => {
    navigate('/nomination', { state: { data: state.data } });
  };

  return (
    <>
      <Header />
      <div className="h-[70vh] w-full flex justify-center items-center gap-x-10  ">
        <Button
          className="!bg-gray-400 hover:!bg-[#515fed] py-5 px-10 lg:!p-2"
          showArrow={false}
          title="Withdraw"
          onClick={onWidthdraw}
          style={{ width: 'auto' }}
        />
        <Button
          className="!bg-gray-400 hover:!bg-[#515fed]  py-5 px-10 lg:!p-2"
          showArrow={false}
          title="Edit Nomination"
          onClick={onEdit}
          style={{ width: 'auto' }}
        />
        {/* <SimpleButton title="Withdraw" onClick={onWidthdraw} />
        <SimpleButton title="Edit Nomination" onClick={onEdit} /> */}
      </div>
    </>
  );
};

export default ViewButtons;
