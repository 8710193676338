import { RESET_FORM, SET_USER } from "./actions";

const initialState = {
  userDetails: {},
  resetForm: false,
};

const commonReducer = (state = initialState, { type, payload, ...rest }) => {
  console.log(type, "action.type");
  switch (type) {
    case SET_USER: {
      return { ...state, userDetails: payload };
    }
    case RESET_FORM: {
      return { ...state, resetForm: payload };
    }

    default:
      return state;
  }
};
export default commonReducer;
