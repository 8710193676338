import React from "react";

class ErrorBoundry extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }
  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true, error: error, errorInfo: errorInfo });
    console.log(error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <h1>Something went wrong!</h1>
          <p>{this.state.error && this.state.error.toString()}</p>
          <p>Component Stack Error Details:</p>
          <p>{this.state.errorInfo.componentStack}</p>
        </>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundry;
