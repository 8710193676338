import axios from "axios";
import axiosRetry from "axios-retry";
import { apiBaseUrl } from "./apis";
const apiInstance = () => {
  const api = axios.create({
    baseURL: apiBaseUrl,
  });
  axiosRetry(api, { retries: 3 });

  api.interceptors.request.use(async (config) => {
    let accessToken = JSON.parse(localStorage.getItem("userDetails"));

    console.log(accessToken?.token, "setToekn");
    if (accessToken?.token) {
      config.headers.authorization = ` Bearer ${accessToken.token}`;
    }
    console.log("REQUEST", config);
    return config;
  });

  api.interceptors.response.use(
    (response) => {
      console.log("RESPONSE", response);
      return response;
    },
    (error) => {
      console.log("ERROR", error, error.response);
      if (error?.response?.status === 401) {
        console.log("ERROR dncjbskdbcs");
        window.location.href = "/";
      }
      throw error;
    }
  );

  return api;
};

const apiClient = apiInstance();

export default apiClient;
