import BootstrapCard from "react-bootstrap/Card";
import "./style.css";

const Card = ({ scrollTop = false, ...props }) => {
  const card = document.getElementById("bCard");
  if (scrollTop) {
    card.scrollTop = 0;
  }
  return (
    <BootstrapCard className="bootstrapCard">
      <BootstrapCard.Body
        id="bCard"
        style={{
          height: "100%",
          width: "100%",
          overflow: "auto",
        }}
      >
        {props.children}
      </BootstrapCard.Body>
    </BootstrapCard>
  );
};

export default Card;
