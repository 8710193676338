import { useState } from "react";
import SimpleInput from "../simpleInput";
import CheckBox from "../checkbox";

const AddessForm = ({
  showCheckBox = false,
  values,
  setFieldValue,
  errors,
  touched,
  addressSameAsMine = false,
  addressKey = "address",
  setAddresSameAsMine = () => {},
}) => {
  // console.log(values.addressKey, "values address");
  const [checked, setChecked] = useState(true);
  const localStorageValues = JSON.parse(localStorage.getItem("step1"));
  const user = localStorageValues ? localStorageValues.user : {};
  const hasError = (field) => {
    let erradd = errors[addressKey] ? errors[addressKey] : {};
    let touchedadd = touched[addressKey] ? touched[addressKey] : {};
    if (touchedadd[field] && erradd[field]) {
      return erradd[field];
    } else {
      return "";
    }
  };
  return (
    <>
      <div className="row justify-content-center align-items-center mt-4">
        <div className="col-md-6 col-sm-6 col-12">
          <h5>US Address</h5>
        </div>
        <div className="col-md-6 col-sm-6 col-12"></div>
      </div>
      {showCheckBox && (
        <div className="row justify-content-center align-items-center mt-5">
          <div className="col-md-6 col-sm-6 col-12">
            <CheckBox
              label="Same as mine"
              checked={checked}
              onChange={(e) => {
                setChecked(!checked);
                if (e.target.checked) {
                  setFieldValue(`${addressKey}.street`, user.address.street);
                  setFieldValue(
                    `${addressKey}.apartment`,
                    user.address.apartment
                  );
                  setFieldValue(`${addressKey}.city`, user.address.city);
                  setFieldValue(`${addressKey}.state`, user.address.state);
                  setFieldValue(`${addressKey}.zipcode`, user.address.zipcode);
                } else {
                  setFieldValue(`${addressKey}.street`, "");
                  setFieldValue(`${addressKey}.apartment`, "");
                  setFieldValue(`${addressKey}.city`, "");
                  setFieldValue(`${addressKey}.state`, "");
                  setFieldValue(`${addressKey}.zipcode`, "");
                }
              }}
            />
          </div>
          <div className="col-md-6 col-sm-6 col-12"></div>
        </div>
      )}

      <div className="row justify-content-center align-items-center mt-4">
        <div className="col-md-6 col-sm-6 col-12">
          <SimpleInput
            label="Street"
            placeholder="Enter street name and number"
            onChange={(e) => {
              setFieldValue(`${addressKey}.street`, e.target.value);
            }}
            value={values[addressKey].street}
            required
            error={hasError("street")}
          />
        </div>
        <div className="col-md-6 col-sm-6 col-12">
          <SimpleInput
            label="Apartment"
            placeholder="Enter apartment"
            type="text"
            onChange={(e) => {
              setFieldValue(`${addressKey}.apartment`, e.target.value);
            }}
            value={values[addressKey].apartment}
            error={hasError("apartment")}
          />
        </div>
      </div>
      <div className="row justify-content-center align-items-center mt-4">
        <div className="col-md-6 col-sm-6 col-12">
          <SimpleInput
            label="City"
            placeholder="Enter city"
            type="text"
            onChange={(e) => {
              setFieldValue(`${addressKey}.city`, e.target.value);
            }}
            value={values[addressKey].city}
            required
            error={hasError("city")}
          />
        </div>
        <div className="col-md-6 col-sm-6 col-12">
          <SimpleInput
            label="State"
            placeholder="Enter state"
            type="text"
            onChange={(e) => {
              setFieldValue(`${addressKey}.state`, e.target.value);
            }}
            value={values[addressKey].state}
            required
            error={hasError("state")}
          />
        </div>
      </div>
      <div className="row justify-content-center align-items-center mt-4">
        <div className="col-md-6 col-sm-6 col-12">
          <SimpleInput
            label="Zip Code"
            placeholder="Enter zip code"
            type="number"
            onChange={(e) => {
              setFieldValue(`${addressKey}.zipcode`, e.target.value);
            }}
            value={values[addressKey].zipcode}
            required
            error={hasError("zipcode")}
            zipInput
            maxLength="5"
          />
        </div>
        <div className="col-md-6 col-sm-6 col-12"></div>
      </div>
    </>
  );
};

export default AddessForm;
