import heroImage from "./HeroAreaImage.jpg";
import logo from "./logo.jpg";
import background from "./background2.jpg";
import user from "./user_icon.png";
import lock from "./lock.png";
import sizes from "./sizes.png";
import slider1 from "./slider1.jpg";
import slider2 from "./slider2.jpg";
import slider3 from "./slider3.jpg";
import slider4 from "./slider4.jpg";
import slider5 from "./slider5.jpg";
import slider6 from "./slider6.jpg";
import slider7 from "./slider7.jpg";
import slider8 from "./slider8.jpg";
import slider9 from "./slider9.jpg";
import slider10 from "./slider10.jpg";
import slider11 from "./slider11.jpg";
import slider12 from "./slider12.jpg";
import slider13 from "./slider13.jpg";
import slider14 from "./slider14.jpg";
import slider15 from "./slider15.jpg";
import slider16 from "./slider16.jpg";
import slider17 from "./slider17.jpg";
import slider18 from "./slider18.jpg";
import transparentLogo from "./transparentLogo.png";

export const images = {
  logo,
  heroImage,
  background,
  user,
  lock,
  sizes,
  slider1,
  slider2,
  slider3,
  slider4,
  slider5,
  slider6,
  slider7,
  slider8,
  slider9,
  slider10,
  slider11,
  slider12,
  slider13,
  slider14,
  slider15,
  slider16,
  slider17,
  slider18,
  transparentLogo,
};
