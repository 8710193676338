import React, { useEffect, useState } from "react";
import "./styles.css";
import apiUrls from "../../apis/apis";
import usePostQuery from "../../hooks/postQuery.hook";
import TShirtSizeCard from "./card";
import Button from "../../components/buttton";
import Header from "../../components/header";
import { images } from "../../assets/images";
import { useSelector } from "react-redux";
import ImageLightBox from "../../components/imageLightBox";

const TShirtSize = () => {
  const [open, setOpen] = useState(false);
  const { postQuery, data } = usePostQuery();
  const { family_id } = useSelector(
    (state) => state?.commonReducer?.userDetails || {}
  );
  const [showSelectSize, setShowSelectSize] = useState(null);

  const fetchUserData = async () => {
    try {
      const response = await postQuery({
        url: apiUrls.getUser,
        postData: {
          family_id: family_id,
        },
      });

      console.log(response.data, "data");
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const haveYouBooked = () => {
    return (
      <>
        <h1 style={{ fontSize: 20, fontWeight: "bold" }}>
          Did you already make booking for KPS Convention 2024?
        </h1>
        <div
          style={{
            marginTop: 20,
            display: "flex",
            flexDirection: "row",
            // justifyContent: 'space-around',
            columnGap: 20,
            marginBottom: 20,
          }}
        >
          <Button
            showArrow={false}
            title="Not Yet"
            onClick={() => {
              if (showSelectSize) {
                setShowSelectSize("no");
              }
            }}
            style={{
              backgroundColor: showSelectSize === "no" ? "#5352ed" : "#ced6e0",
              color: showSelectSize === "no" ? "white" : "black",
            }}
          />
          <Button
            showArrow={false}
            title="Yes"
            onClick={() => setShowSelectSize("yes")}
            style={{
              backgroundColor: showSelectSize === "yes" ? "#5352ed" : "#ced6e0",
              color: showSelectSize === "yes" ? "white" : "black",
            }}
          />
        </div>
      </>
    );
  };

  const header = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div>
          <h1 style={{ fontSize: 20, fontWeight: "bold" }}>
            Select t-shirt size for your family members.
          </h1>
          <h1 style={{ marginTop: 20 }}>
            Not sure about the size? Check the{" "}
            <strong className="sizeChart" onClick={() => setOpen(true)}>
              size chart
            </strong>
          </h1>
        </div>
        <ImageLightBox
          isOpen={open}
          setIsopen={() => setOpen(false)}
          images={[{ src: images.sizes, width: 300, height: 2560 }]}
        />
      </div>
    );
  };

  return (
    <div>
      {haveYouBooked()}
      {showSelectSize === "yes" && header()}
      <div
        style={{
          display: "flex",
          // flexDirection: 'column',
          alignItems: "center",
          width: "100%",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          gap: 20,
          marginTop: "1.5rem",
        }}
      >
        {showSelectSize === "yes" &&
          data?.data?.map((item, index) => {
            return (
              <TShirtSizeCard
                index={index}
                person={item}
                fetchUserData={fetchUserData}
              />
            );
          })}
      </div>
    </div>
  );
};

export default TShirtSize;
