import React, { useEffect, useState } from "react";
import SignatureComponent from "../../components/signatureComponent";
import { useSelector } from "react-redux";
import apiUrls from "../../apis/apis";
import usePostQuery from "../../hooks/postQuery.hook";
import { useLocation } from "react-router-dom";
import moment from "moment";
import Header from "../../components/header";

const FormPage = () => {
  const { user } = useSelector((state) => state.commonReducer.userDetails);
  const { state } = useLocation();
  const nominationData = state?.data || {};
  const [nominationDetails, setNominationDetails] = useState({
    user_id: nominationData.user_id || "",
    position: nominationData.position || "",
    signature_url: nominationData.signature_url || "",
  });

  const getname = (user) => {
    let name = "";
    if (user.first_name) {
      name += user.first_name;
    }
    if (user.middle_name) {
      name += " " + user.middle_name;
    }
    if (user.last_name) {
      name += " " + user.last_name;
    }
    return name;
  };

  const getAddress = (user) => {
    const address = user.address || {};
    let result = "";
    if (address.street) {
      result += address.street;
    }
    if (address.appartment) {
      result += ", " + address.appartment;
    }
    if (address.city) {
      result += ", " + address.city;
    }
    if (address.state) {
      result += ", " + address.state;
    }
    if (address.zipcode) {
      result += ", " + address.zipcode;
    }
    return result;
  };

  const getAge = (dateString) => {
    if (dateString) {
      const today = moment();
      const birthDate = moment(dateString);
      return today.diff(birthDate, "years");
    } else {
      return 0;
    }
  };

  return (
    <div>
      <Header />
      <div className="py-10 w-full lg:w-3/5 px-5 lg:px-0  mx-auto">
        <h2 className=" heading text-lg underline text-center">
          Kachhia Patidar Samaj of the USA
        </h2>
        <p className="text-md-center italic font-medium">
          Board of Directors and Board of Trustees Nomination
        </p>
        <div className="mt-10">
          <p className="text-md font-semibold underline">
            The criteria for placement on the Board of Directors are as follows:
          </p>
          <ol className="list-inside list-decimal mt-2 ml-5">
            <li className="font-semibold">
              Youth Board Member Qualifications:
              <div className="ml-5 font-normal">
                Youth Board Members must be between 18 and 33 years of age at
                the time of election or selection. In addition to fulfilling all
                duties of the Board of Directors, Youth Board members must be
                available to help plan and facilitate the KPS Youth Trip.
              </div>
            </li>
            <li className="font-semibold">
              Adult Board Member Qualifications:
              <div className="ml-5 font-normal">
                Adult Board Members must be over the age of 33 at the time of
                election or selection. To be elected as an adult representative,
                a Samaj member must be an independently paying member of the
                KPS.
              </div>
            </li>
            <li className="font-semibold">
              Qualifications for ALL Board Members:
              <div className="ml-5 font-normal">
                Any Samaj member running for the Board of Directors must be a
                U.S. Citizen or legal Permanent Resident. No person holding a
                valid student, business, or work visa may serve on the Board of
                Directors. Any Samaj member running for the board of directors
                must have lived in the United States for 3 years as of election
                time. All board members must also be available to attend 3
                meetings in person annually and teleconference meetings as
                necessary.
              </div>
            </li>
          </ol>
          <div className="text-md font-semibold underline mt-2">
            The criteria for placement on the Board of Trustees are as follows:
          </div>
          <ol className="list-inside list-decimal mt-2 ml-5">
            <li>
              35 years of age or older at the time of election or selection
            </li>
            <li>Member of the Samaj for the duration of their entire term</li>
            <li>
              Must have served for at least 2 previous terms on the Board of
              Directors
            </li>
            <li>
              Additional Service to the KPS of North America will be considered
              an added credential
            </li>
          </ol>
          <div className="text-md font-semibold underline mt-2">
            Please select which position you are interested in (please check
            only one):
          </div>
          <ol className=" mt-2 ml-5">
            <li>
              <input
                disabled={getAge(user?.dob) < 18 || getAge(user?.dob) > 33}
                checked={
                  nominationDetails.position ===
                  "Board of Directors - Youth Member"
                }
                onChange={() =>
                  setNominationDetails({
                    ...nominationDetails,
                    position: "Board of Directors - Youth Member",
                  })
                }
                type="radio"
                name=""
                id=""
              />{" "}
              Board of Directors - Youth Member
            </li>
            <li>
              <input
                disabled={getAge(user?.dob) <= 33}
                onChange={() =>
                  setNominationDetails({
                    ...nominationDetails,
                    position: "Board of Directors - Adult Member",
                  })
                }
                type="radio"
                name=""
                id=""
                checked={
                  nominationDetails.position ===
                  "Board of Directors - Adult Member"
                }
              />{" "}
              Board of Directors - Adult Member
            </li>
            <li>
              <input
                disabled={getAge(user?.dob) < 35}
                onChange={() =>
                  setNominationDetails({
                    ...nominationDetails,
                    position: "Board of Trustees",
                  })
                }
                checked={nominationDetails.position === "Board of Trustees"}
                type="radio"
                name=""
                id=""
              />{" "}
              Board of Trustees
            </li>
          </ol>
          <div className="text-md font-semibold underline mt-2">
            Please fill in the following about yourself:
          </div>
          <form className="mt-2 flex flex-col gap-y-2 w-full lg:w-2/3">
            <div className="flex w-full">
              <label htmlFor="">Name:</label>
              <input
                disabled
                value={getname(user)}
                className="border-b border-black w-full px-2"
                type="text"
                name="name"
                id=""
              />
            </div>
            <div className="flex w-full">
              <label htmlFor="">Address:</label>
              <input
                disabled
                value={getAddress(user)}
                className="border-b border-black w-full px-2"
                type="address"
                name="address"
                id=""
              />
            </div>
            <div className="flex w-full">
              <label className="text-nowrap" htmlFor="">
                Phone (home/work/cell):
              </label>
              <input
                disabled
                value={user?.mobile_number}
                className="border-b border-black w-full px-2"
                type="tel"
                name="phone"
                id=""
              />
            </div>
            <div className="flex w-full">
              <label className="text-nowrap" htmlFor="">
                E-mail Address:
              </label>
              <input
                disabled
                value={user?.email}
                className="border-b border-black w-full px-2"
                type="email"
                name="email"
                id=""
              />
            </div>
          </form>
          <SignatureComponent
            user_id={user?._id}
            nomination_id={nominationData?._id}
            setNominationDetails={setNominationDetails}
            nominationDetails={nominationDetails}
          />
        </div>
      </div>
    </div>
  );
};

export default FormPage;
