import { useState } from "react";
import apiClient from "../apis/api-client";

const usePostQuery = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [error, setError] = useState();

  const postQuery = async (params) => {
    const {
      url,
      onSuccess = () => {
        console.log("onSuccess function");
      },
      onFail = () => {
        console.log("onFail function");
      },
      postData,
    } = params;
    setLoading(true);
    try {
      const { data: apiData = {} } = await apiClient.post(url, postData);
      setData(apiData);
      await onSuccess(apiData);
      console.log(apiData, "postQuery-success");
    } catch (err) {
      onFail(err);
      console.log(err, "postQuery-fail");
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return {
    postQuery,
    loading,
    setLoading,
    data,
    setData,
    error,
    setError,
  };
};

export default usePostQuery;
