const baseUrlWithSlash =
  // "http://localhost:8000/";
  "https://54.163.150.48.nip.io/";
// 'https://registration-form-backend-delta.vercel.app/';

const apiUrls = {
  createKpsPersons: "api/v1/kps_persons/create",
  updateTshirtSize: "api/v1/kps_persons/person/edit",
  getUser: "api/v1/kps_persons/persons/get",
  updateStatus: "api/v1/kps_persons/person/updateStatus",
  sendOtp: "api/v1/auth/sendOtp",
  verifyOtp: "api/v1/auth/verifyOtp",
  verifyMobileAndEmail: "api/v1/kps_persons/verify_mobile_and_email",
  login: "api/v1/auth/login",
  getNomination: "api/v1/nomination/get",
  updateNomination: "api/v1/nomination/update",
  createNomination: "api/v1/nomination/create",
  uploadSignature: "api/v1/nomination/uploadSignature",
  getAllNominations: "api/v1/nomination/getAllNominations",
};

export { baseUrlWithSlash as apiBaseUrl };
export default apiUrls;
