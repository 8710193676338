import React, { useState } from "react";
import { matchSorter } from "match-sorter";
import "./styles.css";
// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  onFocus = () => {},
  onBlur = () => {},
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const [borderColor, setBorderColor] = useState("#808080");
  const onChange = (value) => {
    if (value?.length >= 3 || !value) {
      setGlobalFilter(value);
    }
  };

  return (
    <span
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <span>
        <i className="fas fa-search"></i>
        <span className="ml-1">Search: </span>
        <input
          className="filter-input"
          // onFocus={{ setBorderColor("red") }}
          id="filter-input-focus"
          value={value || ""}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={`${count} records...`}
          onFocus={onFocus}
          onBlur={onBlur}
          style={{ width: 800 }}
          //   style={{
          //     //fontSize: "1.1rem",
          //     border: "0",
          //   }}
        />
      </span>
      <div
        className="mr-3"
        onClick={() => {
          setValue("");
          setGlobalFilter("");
        }}
      >
        <i
          class="fas fa-times-circle"
          style={{ fontSize: 18, cursor: "pointer" }}
        ></i>
      </div>
    </span>
  );
}

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue}
      className="filter-input"
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Search records...`}
    />
  );
}

function ageColumnFilter() {
  return <div className="filter-input"></div>;
}

function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  return (
    <select
      class="form-select"
      style={{
        width: "100%",
        minWidth: "100px",
      }}
      aria-label="Default select example"
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options
        .filter((el) => el !== null && typeof el !== "undefined")
        .map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
    </select>
  );
}

function SliderColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <>
      <input
        type="range"
        min={min}
        max={max}
        value={filterValue || min}
        onChange={(e) => {
          setFilter(parseInt(e.target.value, 10));
        }}
      />
      <button onClick={() => setFilter(undefined)}>Off</button>
    </>
  );
}

function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <input
        value={filterValue[0] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1],
          ]);
        }}
        placeholder={`Min (${min})`}
        style={{
          width: "70px",
          marginRight: "0.5rem",
        }}
      />
      to
      <input
        value={filterValue[1] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined,
          ]);
        }}
        placeholder={`Max (${max})`}
        style={{
          width: "70px",
          marginLeft: "0.5rem",
        }}
      />
    </div>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

export {
  GlobalFilter,
  DefaultColumnFilter,
  ageColumnFilter,
  SelectColumnFilter,
  NumberRangeColumnFilter,
  SliderColumnFilter,
  fuzzyTextFilterFn,
};
