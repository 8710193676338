import * as yup from "yup";
import { emailRegExp, phoneRegExp, zipcodeRegExp } from "../utils/regex";

// father_name: "",
//     father_deceased: false,
//     father_phone: "",
//     father_email: "",
//     father_address: {
//       street: "",
//       appartement: "",
//       city: "",
//       state: "",
//       zipcode: "",
//     },
//     mother_name: "",
//     mother_phone: "",
//     mother_email: "",
//     mother_address: {
//       street: "",
//       appartement: "",
//       city: "",
//       state: "",
//       zipcode: "",
//     },

const step3Validations = yup.object().shape({
  father_first_name: yup.string().required("This field is required"),
  father_middle_name: yup.string(),
  father_last_name: yup.string().required("This field is required"),
  father_deceased: yup.boolean().required("This field is required"),
  father_phone: yup.string().when("father_deceased", {
    is: false,
    then: () => yup.string().matches(phoneRegExp, "Invalid phone number"),
    otherwise: () => yup.string(),
  }),
  father_email: yup.string().when("father_deceased", {
    is: false,
    then: () => yup.string().matches(emailRegExp, "Invalid email address"),
    otherwise: () => yup.string(),
  }),
  father_dob: yup.date().when("father_deceased", {
    is: false,
    then: () => yup.date().required("This field is required"),
    otherwise: () => yup.date(),
  }),
  father_address: yup.object().when("father_deceased", {
    is: false,
    then: () =>
      yup.object().shape({
        street: yup.string().required("This field is required"),
        appartment: yup.string(),
        city: yup.string().required("This field is required"),
        state: yup.string().required("This field is required"),
        zipcode: yup.string().required("This field is required"),
      }),
    otherwise: () => yup.object(),
  }),
  mother_first_name: yup.string().required("This field is required"),
  mother_middle_name: yup.string(),
  mother_last_name: yup.string().required("This field is required"),
  mother_deceased: yup.boolean().required("This field is required"),
  mother_phone: yup.string().when("mother_deceased", {
    is: false,
    then: () => yup.string().matches(phoneRegExp, "Invalid phone number"),
    otherwise: () => yup.string(),
  }),
  mother_email: yup.string().when("mother_deceased", {
    is: false,
    then: () => yup.string().matches(emailRegExp, "Invalid email address"),
    otherwise: () => yup.string(),
  }),
  mother_dob: yup.date().when("mother_deceased", {
    is: false,
    then: () => yup.date().required("This field is required"),
    otherwise: () => yup.date(),
  }),
  mother_address: yup.object().when("mother_deceased", {
    is: false,
    then: () =>
      yup.object().shape({
        street: yup.string().required("This field is required"),
        appartment: yup.string(),
        city: yup.string().required("This field is required"),
        state: yup.string().required("This field is required"),
        zipcode: yup.string().required("This field is required"),
      }),
    otherwise: () => yup.object(),
  }),
});

export default step3Validations;
