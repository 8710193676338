import _ from "lodash";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom
import useQuery from "./containers/useQuery";
import { Link } from "react-router-dom";

const TablePagination = ({
  gotoPage,
  nextPage,
  canPreviousPage,
  canNextPage,
  previousPage,
  pageIndex,
  pageOptions,
  pageCount,
}) => {
  useEffect(() => {
    const storedPageIndex = localStorage.getItem("pageIndex");
    if (storedPageIndex) {
      gotoPage(parseInt(storedPageIndex));
    } else if (pageIndex + 1 > pageOptions?.length) {
      gotoPage(0);
    }
  }, [pageIndex, gotoPage, pageOptions]);

  const setPageIndex = (index) => {
    localStorage.setItem("pageIndex", index);
  };

  return (
    <div className="page-numbers-container">
      <button
        className="page-link"
        onClick={() => {
          setPageIndex(pageIndex - 1);
          previousPage();
        }}
        disabled={!canPreviousPage}
      >
        Previous
      </button>

      {pageOptions.map((page, index) => (
        <button
          key={index}
          className={`page-link${pageIndex === index ? " active" : ""}`}
          onClick={() => {
            setPageIndex(index);
            gotoPage(index);
          }}
        >
          {index + 1}
        </button>
      ))}

      <button
        className="page-link"
        onClick={() => {
          setPageIndex(pageIndex + 1);
          nextPage();
        }}
        disabled={!canNextPage}
      >
        Next
      </button>
    </div>
  );
};

export default TablePagination;
