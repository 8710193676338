import React, { useEffect, useState } from "react";
import CustomTable from "../../components/table";
import "./styles.css";
import useGetQuery from "../../hooks/getQuery.hook";
import apiUrls from "../../apis/apis";
import usePostQuery from "../../hooks/postQuery.hook";
import usePatchQuery from "../../hooks/patchQuery.hook";
import axios from "axios";
import Loader from "../../components/loader";
import Header from "../../components/header";
import moment from "moment";

const UserList = () => {
  const [userType, setUserType] = useState("regular");
  const [rowStatus, setRowStatus] = useState("");
  const [tableData, setTableData] = useState([]);

  console.log("table ", tableData);
  const { postQuery, data, loading } = usePostQuery();
  const fetchUserData = async () => {
    try {
      await postQuery({
        url: apiUrls.getUser,
        postData: {},
        onSuccess: (response) => {
          setTableData(response.data || []);
        },
      });
    } catch (error) {
      console.log(error, "error");
    }
  };
  useEffect(() => {
    fetchUserData();
  }, []);

  const handleStatus = async (id, status) => {
    try {
      const response = await postQuery({
        url: apiUrls.updateStatus,
        postData: {
          _id: id,
          status: status,
        },
      });
      console.log(response);
      fetchUserData();
    } catch (error) {
      console.error(error);
    }
  };

  const columns = [
    {
      Header: "Name",
      // accessor: "name",
      Cell: ({ row }) => {
        if (row?.original?.name) {
          return (
            <>
              <div
                style={{
                  width: "200px",
                }}
              >
                {row?.original?.name || "-"}
              </div>
            </>
          );
        } else {
          if (row?.original?.middle_name) {
            return (
              <>
                <div
                  style={{
                    width: "200px",
                  }}
                >
                  {row?.original?.first_name +
                    " " +
                    row?.original?.middle_name +
                    " " +
                    row?.original?.last_name}
                </div>
              </>
            );
          } else {
            return (
              <>
                <div>
                  {row?.original?.first_name + " " + row?.original?.last_name}
                </div>
              </>
            );
          }
        }
      },
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: ({ row }) => {
        return (
          <>
            <div>{row?.original?.email || "-"}</div>
          </>
        );
      },
    },
    {
      Header: "Mobile Number",
      accessor: "mobile_number",
      Cell: ({ row }) => {
        return (
          <>
            <div>{row?.original?.mobile_number || "-"}</div>
          </>
        );
      },
    },
    {
      Header: "Date of birth",
      accessor: "dob",
      Cell: ({ row }) => {
        return (
          <>
            <div>{moment(row?.original?.dob).format("MM/DD/YYYY")}</div>
          </>
        );
      },
    },
    {
      Header: "Gender",
      accessor: "gender",
      Cell: ({ row }) => {
        return (
          <>
            <div>{row?.original?.gender || "-"}</div>
          </>
        );
      },
    },
    {
      Header: "Tshirt size",
      accessor: "tshirt_size",
      Cell: ({ row }) => {
        return (
          <>
            <div>{row?.original?.tshirt_size || "-"}</div>
          </>
        );
      },
    },

    {
      Header: "Actions",
      accessor: "actions",
      show: userType === "regular",
      Cell: ({ row }) => {
        return (
          <>
            <button
              className={
                row.original.status === "accepted" ? "btn" : "btn btnUnselected"
              }
              onClick={() => handleStatus(row.original._id, "accepted")}
            >
              Accept
            </button>
            <button
              className={
                row.original.status === "rejected"
                  ? "btn Rej"
                  : "btn btnUnselected"
              }
              onClick={() => handleStatus(row.original._id, "rejected")}
            >
              Reject
            </button>
          </>
        );
      },
    },
  ];

  return (
    <div>
      <Header />
      <Loader loading={loading} />
      <CustomTable data={tableData} columns={columns} userType={userType} />
    </div>
  );
};

export default UserList;
