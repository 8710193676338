import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { images } from "../../assets/images";
import styles from "./index.module.scss";
import { Pagination, Navigation, Autoplay } from "swiper/modules";

const BannerSlider = () => {
  return (
    <Swiper
      pagination={{
        dynamicBullets: true,
      }}
      navigation={true}
      modules={[Pagination, Navigation, Autoplay]}
      loop={true}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      slidesPerView={1}
      className={styles["bannerSlider"]}
    >
      <SwiperSlide className={styles["slide"]}>
        <img src={images.slider1} alt="" />
      </SwiperSlide>
      <SwiperSlide className={styles["slide"]}>
        <img src={images.slider2} alt="" />
      </SwiperSlide>
      <SwiperSlide className={styles["slide"]}>
        <img src={images.slider3} alt="" />
      </SwiperSlide>
      <SwiperSlide className={styles["slide"]}>
        <img src={images.slider4} alt="" />
      </SwiperSlide>
      <SwiperSlide className={styles["slide"]}>
        <img src={images.slider5} alt="" />
      </SwiperSlide>
      <SwiperSlide className={styles["slide"]}>
        <img src={images.slider6} alt="" />
      </SwiperSlide>
      <SwiperSlide className={styles["slide"]}>
        <img src={images.slider7} alt="" />
      </SwiperSlide>
      <SwiperSlide className={styles["slide"]}>
        <img src={images.slider8} alt="" />
      </SwiperSlide>
      <SwiperSlide className={styles["slide"]}>
        <img src={images.slider9} alt="" />
      </SwiperSlide>
      <SwiperSlide className={styles["slide"]}>
        <img src={images.slider10} alt="" />
      </SwiperSlide>
      <SwiperSlide className={styles["slide"]}>
        <img src={images.slider11} alt="" />
      </SwiperSlide>
      <SwiperSlide className={styles["slide"]}>
        <img src={images.slider12} alt="" />
      </SwiperSlide>
      <SwiperSlide className={styles["slide"]}>
        <img src={images.slider13} alt="" />
      </SwiperSlide>
      <SwiperSlide className={styles["slide"]}>
        <img src={images.slider14} alt="" />
      </SwiperSlide>
      <SwiperSlide className={styles["slide"]}>
        <img src={images.slider15} alt="" />
      </SwiperSlide>
      <SwiperSlide className={styles["slide"]}>
        <img src={images.slider16} alt="" />
      </SwiperSlide>
      <SwiperSlide className={styles["slide"]}>
        <img src={images.slider17} alt="" />
      </SwiperSlide>
      <SwiperSlide className={styles["slide"]}>
        <img src={images.slider18} alt="" />
      </SwiperSlide>
    </Swiper>
  );
};

export default BannerSlider;
