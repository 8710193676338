import { useState } from "react";
import SimpleInput from "../../components/simpleInput";
import CheckBox from "../../components/checkbox";

const ChildAddress = ({
  showCheckBox = false,
  values,
  setFieldValue,
  errors,
  touched,
  addressSameAsMine = false,
  setAddresSameAsMine = () => {},
  sequence,
  addressKey,
}) => {
  const [checked, setChecked] = useState(true);
  const localStorageValues = JSON.parse(localStorage.getItem("step1"));
  const user = localStorageValues ? localStorageValues.user : {};
  const hasError = (field) => {
    let erradd =
      errors &&
      errors.children &&
      errors.children[sequence] &&
      errors.children[sequence].address &&
      errors.children[sequence].address
        ? errors.children[sequence].address
        : {};
    let touchedadd =
      touched &&
      touched.children &&
      touched.children[sequence] &&
      touched.children[sequence].address &&
      touched.children[sequence].address
        ? touched.children[sequence].address
        : {};
    if (touchedadd[field] && erradd[field]) {
      return erradd[field];
    } else {
      return "";
    }
  };
  return (
    <>
      <div className="row justify-content-center align-items-center mt-5">
        <div className="col-md-6 col-sm-6 col-12">
          <h5>US Address</h5>
        </div>
        <div className="col-md-6 col-sm-6 col-12"></div>
      </div>
      {showCheckBox && (
        <div className="row justify-content-center align-items-center mt-3">
          <div className="col-md-6 col-sm-6 col-12">
            <CheckBox
              label="Same as mine"
              checked={checked}
              onChange={(e) => {
                if (e.target.checked) {
                  let temp = values.children;
                  temp[sequence].address.street = user.address.street;
                  temp[sequence].address.apartment = user.address.apartment;
                  temp[sequence].address.city = user.address.city;
                  temp[sequence].address.state = user.address.state;
                  temp[sequence].address.zipcode = user.address.zipcode;
                  setFieldValue("children", temp);
                } else {
                  let temp = values.children;
                  temp[sequence].address.street = "";
                  temp[sequence].address.apartment = "";
                  temp[sequence].address.city = "";
                  temp[sequence].address.state = "";
                  temp[sequence].address.zipcode = "";
                  setFieldValue("children", temp);
                }

                setChecked(!checked);
              }}
            />
          </div>
          <div className="col-md-6 col-sm-6 col-12"></div>
        </div>
      )}

      <div className="row justify-content-center align-items-center mt-3">
        <div className="col-md-6 col-sm-6 col-12">
          <SimpleInput
            label="Street"
            placeholder="Enter street"
            onChange={(e) => {
              let temp = values.children;
              temp[sequence].address.street = e.target.value;
              setFieldValue("children", temp);
            }}
            value={values.children[sequence].address.street}
            required
            error={hasError("street")}
          />
        </div>
        <div className="col-md-6 col-sm-6 col-12">
          <SimpleInput
            label="Apartment"
            placeholder="Enter apartment"
            type="text"
            onChange={(e) => {
              setFieldValue(`${addressKey}.apartment`, e.target.value);
            }}
            value={values.children[sequence].address.apartment}
            required
            error={hasError("apartment")}
          />
        </div>
      </div>
      <div className="row justify-content-center align-items-center mt-4">
        <div className="col-md-6 col-sm-6 col-12">
          <SimpleInput
            label="City"
            placeholder="Enter city"
            type="text"
            onChange={(e) => {
              let temp = values.children;
              temp[sequence].address.city = e.target.value;
              setFieldValue("children", temp);
            }}
            value={values.children[sequence].address.city}
            required
            error={hasError("city")}
          />
        </div>
        <div className="col-md-6 col-sm-6 col-12">
          <SimpleInput
            label="State"
            placeholder="Enter state"
            type="text"
            onChange={(e) => {
              let temp = values.children;
              temp[sequence].address.state = e.target.value;
              setFieldValue("children", temp);
            }}
            value={values.children[sequence].address.state}
            required
            error={hasError("state")}
          />
        </div>
      </div>
      <div className="row justify-content-center align-items-center mt-4">
        <div className="col-md-6 col-sm-6 col-12">
          <SimpleInput
            label="Zip Code"
            placeholder="Enter zip code"
            type="number"
            onChange={(e) => {
              let temp = values.children;
              temp[sequence].address.zipcode = e.target.value;
              setFieldValue("children", temp);
            }}
            value={values.children[sequence].address.zipcode}
            required
            error={hasError("zipcode")}
            zipInput
            maxLength="5"
          />
        </div>
        <div className="col-md-6 col-sm-6 col-12"></div>
      </div>
    </>
  );
};

export default ChildAddress;
