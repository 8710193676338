const Circle = ({ showInnerCircle }) => {
  return (
    <div
      style={{
        width: "20px",
        height: "20px",
        borderRadius: "50%",
        border: showInnerCircle ? "3px solid #fff" : "3px solid #7450BE",
        backgroundColor: showInnerCircle ? "#7450BE" : "#fff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <div className="inner-circle">
        {showInnerCircle && (
          <div
            style={{
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              border: "1px solid #7450BE",
              backgroundColor: "#fff",
            }}
          ></div>
        )}
      </div>
    </div>
  );
};

export default Circle;
