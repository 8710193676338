import { Form, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Card from "../../components/card";
import LayOut from "../../components/layout";
import SimpleInput from "../../components/simpleInput";
import CheckBox from "../../components/checkbox";
import { useEffect, useState } from "react";
import AddessForm from "../../components/addressForm";
import Button from "../../components/buttton";
import { Formik } from "formik";
import step2Validations from "./validation";
import usePostQuery from "../../hooks/postQuery.hook";
import apiUrls from "../../apis/apis";
import LoadingSpinner from "../../components/loader";
// import { Formik, Form, Field, ErrorMessage } from "formik";

const Step2 = ({ setActiveStep, setSkipStep4, setScrollTop }) => {
  useEffect(() => {
    setScrollTop(true);
    setTimeout(() => {
      setScrollTop(false);
    }, 1000);
  }, []);
  const navigate = useNavigate();
  const [havespouse, setHavespouse] = useState(false);
  const { postQuery, loading } = usePostQuery();
  const localStorageValues = JSON.parse(localStorage.getItem("step1"));
  let initialValues = {};

  if (localStorageValues && localStorageValues.spouce_details) {
    initialValues = localStorageValues.spouce_details;
  } else {
    initialValues = {
      no_spouse: false,
      first_name: "",
      middle_name: "",
      last_name: "",
      spouse_phone: "",
      spouse_email: "",
      spouse_dob: "",
      spouse_address:
        localStorageValues && localStorageValues.user.address
          ? localStorageValues.user.address
          : {
              street: "",
              appartement: "",
              city: "",
              state: "",
              zipcode: "",
            },
    };
  }
  return (
    <>
      <LoadingSpinner loading={loading} />

      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          console.log(values, "values");
          postQuery({
            url: apiUrls.verifyMobileAndEmail,
            postData: {
              mobile_number: values.spouse_phone ? [values.spouse_phone] : [],
              email: values.spouse_email ? [values.spouse_email] : [],
            },
            onSuccess: () => {
              localStorage.setItem(
                "step1",
                JSON.stringify({
                  ...localStorageValues,
                  spouce_details: { ...values },
                })
              );
              setActiveStep(3);
            },
            onFail: () => {
              toast.error("Email or Mobile already exist");
            },
          });
        }}
        validationSchema={step2Validations}
      >
        {({ values, errors, touched, setFieldValue, submitForm }) => {
          console.log(errors, "errors");
          console.log(values, "values");
          console.log(touched, "touched");
          return (
            <Form>
              <div className="row justify-content-center align-items-center mt-5">
                <div className="col-md-6 col-sm-6 col-12">
                  <CheckBox
                    label="No Spouse"
                    checked={values.no_spouse}
                    onChange={() => {
                      setFieldValue("no_spouse", !values.no_spouse);
                      setSkipStep4(!values.no_spouse);
                      let temp = localStorageValues;
                      delete temp.spouce_details;
                      localStorage.setItem("step1", JSON.stringify(temp));
                    }}
                  />
                </div>
                <div className="col-md-6 col-sm-6 col-12"></div>
              </div>
              {!values.no_spouse && (
                <>
                  <div className="row justify-content-center align-items-center mt-4">
                    <div className="col-md-6 col-sm-6 col-12">
                      <SimpleInput
                        label="Spouse First Name"
                        placeholder="Enter Spouse first name"
                        onChange={(e) => {
                          setFieldValue("first_name", e.target.value);
                        }}
                        required
                        value={values.first_name}
                        error={
                          touched.first_name && errors.first_name
                            ? errors.first_name
                            : null
                        }
                      />
                    </div>
                    <div className="col-md-6 col-sm-6 col-12">
                      <SimpleInput
                        label="Spouse Middle Name"
                        placeholder="Enter Spouse middle name"
                        onChange={(e) => {
                          console.log(e, "e.target.value");
                          setFieldValue("middle_name", e.target.value);
                        }}
                        value={values.middle_name}
                        error={
                          touched.middle_name && errors.middle_name
                            ? errors.middle_name
                            : null
                        }
                      />
                    </div>
                  </div>
                  <div className="row justify-content-center align-items-center mt-4">
                    <div className="col-md-6 col-sm-6 col-12">
                      <SimpleInput
                        label="Spouse Last Name"
                        placeholder="Enter Spouse last name"
                        onChange={(e) => {
                          setFieldValue("last_name", e.target.value);
                        }}
                        required
                        value={values.last_name}
                        error={
                          touched.last_name && errors.last_name
                            ? errors.last_name
                            : null
                        }
                      />
                    </div>
                    <div className="col-md-6 col-sm-6 col-12">
                      <SimpleInput
                        label="Spouse US Phone Number"
                        placeholder="Enter Spouse phone number"
                        type="number"
                        maxLength={"10"}
                        phoneInput
                        onChange={(e) => {
                          console.log(e, "e.target.value");
                          setFieldValue("spouse_phone", e.target.value);
                        }}
                        value={values.spouse_phone}
                        error={
                          touched.spouse_phone && errors.spouse_phone
                            ? errors.spouse_phone
                            : null
                        }
                      />
                    </div>
                  </div>
                  <div className="row justify-content-center align-items-center mt-4">
                    <div className="col-md-6 col-sm-6 col-12">
                      <SimpleInput
                        label="Spouse Email"
                        placeholder="Enter Spouse email"
                        type="text"
                        onChange={(e) => {
                          setFieldValue("spouse_email", e.target.value);
                        }}
                        value={values.spouse_email}
                        error={
                          touched.spouse_email && errors.spouse_email
                            ? errors.spouse_email
                            : null
                        }
                      />
                    </div>
                    <div className="col-md-6 col-sm-6 col-12">
                      <SimpleInput
                        label="Spouse Date of Birth"
                        placeholder="Enter spouse's date of birth"
                        type="date"
                        onChange={(e) => {
                          setFieldValue("spouse_dob", e.target.value);
                        }}
                        value={values.spouse_dob}
                        required
                        error={
                          touched.spouse_dob && errors.spouse_dob
                            ? errors.spouse_dob
                            : null
                        }
                      />
                    </div>
                  </div>
                  <AddessForm
                    showCheckBox={true}
                    values={values}
                    setFieldValue={setFieldValue}
                    addressKey="spouse_address"
                    errors={errors}
                    touched={touched}
                  />
                </>
              )}
              <div className="d-flex row justify-content-end align-items-center mt-4">
                <div className="col-md-6 col-sm-6 col-6">
                  <div
                    className="col-md-6 col-sm-6 col-"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start",
                      alignItems: "center",
                      marginLeft: "30px",
                    }}
                  >
                    <a
                      onClick={() => {
                        setActiveStep(1);
                      }}
                      style={{
                        textDecoration: "none",
                        color: "#7450BE",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <h6>Back</h6>
                    </a>
                  </div>
                </div>
                <div
                  className="col-6 col-md-6 sm-12"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                >
                  <Button
                    title="Next"
                    onClick={submitForm}
                    style={{
                      marginRight: "10px",
                    }}
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default Step2;
