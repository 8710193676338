import styles from './index.module.scss';

const Input = ({
  disabled = false,
  inputProps = {},
  error,
  title,
  containerStyle,
  style = {},
  required = false,
  onChange = () => {},
}) => {
  return (
    <div style={containerStyle} className={styles['inputWrapper']}>
      <label htmlFor="name" className="text-sm text-[#3C405E]">
        {title}
        {required && '*'}
      </label>
      <input
        className={styles['input'] + ' font-4'}
        {...inputProps}
        onChange={(e) => onChange(e)}
        style={style}
        disabled={disabled}
      />
      {error && <p className={styles['error-text']}>{error}</p>}
    </div>
  );
};

export default Input;
