import { Form, useNavigate } from "react-router-dom";
import Card from "../../components/card";
import LayOut from "../../components/layout";
import SimpleInput from "../../components/simpleInput";
import AddessForm from "../../components/addressForm";
import Button from "../../components/buttton";
import { Formik } from "formik";
import step1Validations from "./validations";
import GenderInput from "../../components/genderInput";
import usePostQuery from "../../hooks/postQuery.hook";
import apiUrls, { apiBaseUrl } from "../../apis/apis";
import LoadingSpinner from "../../components/loader";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useSelector } from "react-redux";

// import { Formik, Form, Field, ErrorMessage } from "formik";

const Step1 = ({ setActiveStep, setScrollTop }) => {
  const resetForm = useSelector((state) => state.commonReducer.resetForm);
  useEffect(() => {
    setScrollTop(true);
    setTimeout(() => {
      setScrollTop(false);
    }, 1000);
  }, []);
  const navigate = useNavigate();
  const { postQuery, loading } = usePostQuery();
  const localStorageValues = JSON.parse(localStorage.getItem("step1"));
  let initialValues = {};
  if (localStorageValues && localStorageValues.user && !resetForm) {
    initialValues = localStorageValues.user;
  } else {
    initialValues = {
      first_name: "",
      middle_name: "",
      last_name: "",
      phone: "",
      dob: "",
      email: "",
      address: {
        street: "",
        appartement: "",
        city: "",
        state: "",
        zipcode: "",
      },
      town: "",
      gender: "",
    };
  }
  return (
    <>
      <LoadingSpinner loading={loading} />
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          console.log(values, "values");
          postQuery({
            url: apiUrls.verifyMobileAndEmail,
            postData: {
              mobile_number: [values.phone],
              email: [values.email],
            },
            onSuccess: () => {
              if (localStorage.getItem("step1")) {
                localStorage.setItem(
                  "step1",
                  JSON.stringify({
                    ...localStorageValues,
                    user: { ...values },
                  })
                );
              } else {
                localStorage.setItem(
                  "step1",
                  JSON.stringify({
                    user: { ...values },
                  })
                );
              }
              setActiveStep(2);
            },
            onFail: () => {
              toast.error("Email or Mobile already exist");
            },
          });
        }}
        validationSchema={step1Validations}
      >
        {({ values, errors, touched, setFieldValue, submitForm }) => {
          return (
            <Form>
              <div className="row justify-content-center align-items-center mt-5">
                <div className="col-md-6 col-sm-6 col-12">
                  <SimpleInput
                    label="First Name"
                    placeholder="Enter your first name"
                    onChange={(e) => {
                      setFieldValue("first_name", e.target.value);
                    }}
                    value={values.first_name}
                    required
                    error={
                      touched.first_name && errors.first_name
                        ? errors.first_name
                        : null
                    }
                  />
                </div>
                <div className="col-md-6 col-sm-6 col-12">
                  <SimpleInput
                    label="Middle Name"
                    placeholder="Enter your middle name"
                    onChange={(e) => {
                      setFieldValue("middle_name", e.target.value);
                    }}
                    value={values.middle_name}
                    error={
                      touched.middle_name && errors.middle_name
                        ? errors.middle_name
                        : null
                    }
                  />
                </div>
              </div>
              <div className="row justify-content-center align-items-center mt-4">
                <div className="col-md-6 col-sm-6 col-12">
                  <SimpleInput
                    label="Last Name"
                    placeholder="Enter your last name"
                    onChange={(e) => {
                      setFieldValue("last_name", e.target.value);
                    }}
                    value={values.last_name}
                    required
                    error={
                      touched.last_name && errors.last_name
                        ? errors.last_name
                        : null
                    }
                  />
                </div>
                <div className="col-md-6 col-sm-6 col-12">
                  <SimpleInput
                    phoneInput
                    label="Phone Number"
                    placeholder="Enter your phone number"
                    type="number"
                    maxLength={"10"}
                    onChange={(e) => {
                      setFieldValue("phone", e.target.value);
                    }}
                    value={values.phone}
                    required
                    error={touched.phone && errors.phone ? errors.phone : null}
                  />
                </div>
              </div>
              <div className="row justify-content-center align-items-center mt-4">
                <div className="col-md-6 col-sm-6 col-12">
                  <SimpleInput
                    label="Date of Birth"
                    placeholder="Enter your date of birth"
                    type="date"
                    onChange={(e) => {
                      setFieldValue("dob", e.target.value);
                    }}
                    value={values.dob}
                    required
                    error={touched.dob && errors.dob ? errors.dob : null}
                  />
                </div>
                <div className="col-md-6 col-sm-6 col-12">
                  <SimpleInput
                    label="Email"
                    placeholder="Enter your email"
                    onChange={(e) => {
                      setFieldValue("email", e.target.value);
                    }}
                    value={values.email}
                    required
                    error={touched.email && errors.email ? errors.email : null}
                  />
                </div>
              </div>
              <div className="row justify-content-center align-items-center mt-4">
                <div className="col-md-6 col-sm-6 col-12">
                  <GenderInput
                    value={values.gender}
                    onChange={(e) => {
                      setFieldValue("gender", e);
                    }}
                    key="gender"
                    label={"Select gender"}
                    required
                    error={
                      touched.gender && errors.gender ? errors.gender : null
                    }
                  />
                </div>
                <div className="col-md-6 col-sm-6 col-12"></div>
              </div>
              <AddessForm
                setFieldValue={setFieldValue}
                values={values}
                errors={errors}
                touched={touched}
              />
              <div className="row justify-content-center align-items-center mt-4">
                <div className="col-md-6 col-sm-6 col-12">
                  <SimpleInput
                    label="What Gaam / Town are you or your family from in India?"
                    placeholder="e.g. Mota Varachha / Karcheliya / Bardoli"
                    onChange={(e) => {
                      setFieldValue("town", e.target.value);
                    }}
                    value={values.town}
                    required
                    error={touched.town && errors.town ? errors.town : null}
                  />
                </div>
                <div className="col-md-6 col-sm-6 col-12"></div>
              </div>
              <div className="d-flex row justify-content-end align-items-center mt-3">
                <div className="col-md-6 col-sm-6 col-12"></div>
                <div
                  className="col-6 col-md-6 sm-12"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                >
                  {/* <button type="Submit">
                    <h6>Next</h6>
                  </button> */}
                  <Button
                    title="Next"
                    type="submit"
                    onClick={submitForm}
                    style={{
                      marginRight: "10px",
                    }}
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default Step1;
