import { useState } from "react";
import Circle from "../circle";

const GenderInput = ({
  value,
  onChange,
  key,
  label,
  name,
  error,
  required,
}) => {
  const [gender, setGender] = useState(value || "");
  console.log(value, "gender value");
  // return two div with style like square radio button
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <label htmlFor={name} className="small">
          {label}
          {required && <span className="text-danger">*</span>}
        </label>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
            marginTop: "10px",
          }}
        >
          <a
            onClick={() => {
              onChange("Male");
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "center",
                width: "130px",
                height: "40px",
                border: "1px solid #7450BE",
                borderRadius: "5px",
                backgroundColor:
                  value === "Male" ? "#7450BE" : "rgba(116, 80, 190, 0.05)",
                color: value === "Male" ? "#fff" : "#000",
                marginRight: "20px",
              }}
              className="mobile-font"
            >
              <Circle showInnerCircle={value === "Male"} /> Male
            </div>
          </a>
          <a
            onClick={() => {
              onChange("Female");
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "center",
                width: "130px",
                height: "40px",
                border: "1px solid #7450BE",
                borderRadius: "5px",
                backgroundColor:
                  value === "Female" ? "#7450BE" : "rgba(116, 80, 190, 0.05)",
                color: value === "Female" ? "#fff" : "#000",
              }}
              className="mobile-font"
            >
              <Circle showInnerCircle={value === "Female"} /> Female
            </div>
          </a>
        </div>
        <span
          style={{
            fontSize: "12px",
            height: "10px",
          }}
          className="mt-2 text-danger"
        >
          {error}
        </span>
      </div>
    </>
  );
};

export default GenderInput;
