import { useEffect, useRef, useState } from 'react';
import Step1 from '../step1';
import Step2 from '../step2';
import Step3 from '../step3';
import Step4 from '../step4';
import StepperHeader from './stepperHeader';
import LayOut from '../../components/layout';
import Card from '../../components/card';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RESET_FORM } from '../../redux/actions';

const Stepper = ({}) => {
  const dispatch = useDispatch();
  const resetForm = useSelector((state) => state.commonReducer.resetForm);
  console.log(resetForm, 'resetForm');
  useEffect(() => {
    if (resetForm) {
      localStorage.removeItem('step1');
      dispatch({
        type: RESET_FORM,
        payload: false,
      });
    }
  });

  const [activeStep, setActiveStep] = useState(1);
  const [skipStep4, setSkipStep4] = useState(false);
  const [scrollTop, setScrollTop] = useState(false);
  let headerOptions = [
    { label: 'Self Registration ', value: 1 },
    { label: 'Spouse Registration', value: 2 },
    { label: 'Parents Registration', value: 3 },
    { label: 'Kids Registration', value: 4 },
  ];
  if (skipStep4) {
    headerOptions = headerOptions.filter((item) => item.value !== 4);
  }

  return (
    <LayOut>
      <Card scrollTop={scrollTop}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          {headerOptions.map((item, index) => {
            return (
              <StepperHeader
                active={activeStep === index + 1}
                setActiveStep={setActiveStep}
                label={item.label}
                step={item.value}
              />
            );
          })}
        </div>
        {activeStep === 1 && (
          <Step1 setActiveStep={setActiveStep} setScrollTop={setScrollTop} />
        )}
        {activeStep === 2 && (
          <Step2
            setActiveStep={setActiveStep}
            setSkipStep4={setSkipStep4}
            setScrollTop={setScrollTop}
          />
        )}
        {activeStep === 3 && (
          <Step3
            setActiveStep={setActiveStep}
            setScrollTop={setScrollTop}
            skipStep4={skipStep4}
          />
        )}
        {activeStep === 4 && (
          <Step4 setActiveStep={setActiveStep} setScrollTop={setScrollTop} />
        )}
      </Card>
    </LayOut>
  );
};

export default Stepper;
