import React from 'react';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import './styles.css';

const ImageLightBox = ({
  isOpen = false,
  setIsopen = () => {},
  images = [],
}) => {
  return (
    <div>
      <Lightbox
        open={isOpen}
        close={() => setIsopen(false)}
        slides={images}
        render={{
          buttonPrev: images.length <= 1 ? () => null : undefined,
          buttonNext: images.length <= 1 ? () => null : undefined,
        }}
      />
    </div>
  );
};

export default ImageLightBox;
