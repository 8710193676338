import { images } from "../../assets/images";
import "./style.css";

const LayOut = ({ children }) => {
  return (
    // return a div with full height and width and center the children
    <>
      <div
        className="row justify-content-center align-items-center"
        style={{ marginBottom: "" }}
      >
        <img className="logo" src={images.logo} alt="logo" />
      </div>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100%", width: "100%" }}
      >
        {children}
      </div>
    </>
  );
};

export default LayOut;
