import "./style.css";

const SimpleInput = ({
  phoneInput = false,
  zipInput = false,
  label,
  name,
  type = "text",
  placeholder,
  className = "",
  error = "",
  required = false,
  onChange = () => {},
  value,
  handleBlur = () => {},
  maxLength = "",
  ...props
}) => {
  console.log(phoneInput, "phoneInput");
  const handleWhellChange = (e) => {
    e.target.blur();
    e.stopPropagation();
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  const handleArrowChange = (e) => {
    if (e.which === 38 || e.which === 40) {
      e.preventDefault();
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <label htmlFor={name} className="text-md mobile-font">
        {label}
        {required && <span className="text-danger">*</span>}
      </label>

      <div className="position-relative ">
        <input
          as="input"
          type={type}
          name={name}
          id={name}
          onInput={(e) => {
            if (phoneInput || zipInput) {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, maxLength);
            }
          }}
          maxLength={maxLength}
          placeholder={placeholder}
          className={`col-12 px-3 py-2 border rounded mobile-font ${className} ${
            phoneInput ? "inputForPhone" : ""
          } ${value ? "date" : "inactiveDate"}`}
          style={{
            backgroundColor: "#f0ecf8",
            height: "50px",
            marginTop: "5px",
          }}
          onChange={(e) => onChange(e)}
          value={value}
          {...props}
          onBlur={handleBlur}
          onWheel={type == "number" ? handleWhellChange : () => {}}
          onKeyDown={handleArrowChange}
        />
        {phoneInput && <span className="mobileCode">+1</span>}
      </div>
      <span
        style={{
          height: "10px",
          marginBottom: "5px",
        }}
        className="mt-2 text-danger mobile-font"
      >
        {error}
      </span>
    </div>
  );
};

export default SimpleInput;
