import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import Login from "../pages/login";
import Stepper from "../pages/stepper";
import Home from "../pages/home";
import UserList from "../pages/users";
import FamilyList from "../pages/family";
import Events from "../pages/events";
import TShirtSize from "../pages/tshirtSize";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { SET_USER } from "../redux/actions";
import CreatePassword from "../pages/createPassword";
import FormPage from "../pages/formPage";
import ViewButtons from "../pages/formPage/viewButtons";
import Nominations from "../pages/nominations";

const Routes = () => {
  const dispatch = useDispatch();

  const getUser = async () => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    if (userDetails) {
      dispatch({
        type: SET_USER,
        payload: userDetails,
      });
    }
  };
  useEffect(() => {
    getUser();
  });
  return <RouterProvider router={routes} />;
};
const routes = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Stepper />} />
      <Route path="/user" element={<UserList />} />
      <Route path="/myFamily" element={<FamilyList />} />
      <Route path="/events" element={<Events />} />
      <Route path="/tshirt-size" element={<TShirtSize />} />
      <Route path="/create-password" element={<CreatePassword />} />
      <Route path="/nomination" element={<FormPage />} />
      <Route path="/edit-nomination" element={<ViewButtons />} />
      <Route path="/all-nominations" element={<Nominations />} />
    </>
  )
);

export default Routes;
