import { useState } from 'react';
import styles from './index.module.scss';

const PasswordInput = ({
  value,
  disabled = false,
  inputProps = {},
  error,
  // register,
  label = 'Password',
  placeholder,
  containerStyle,
  style = {},
  required = false,
  onChange = () => {},
}) => {
  const handleChange = (e) => {
    onChange(e);
  };
  return (
    <div style={containerStyle} className={styles['inputWrapper']}>
      <label className="input-label">
        {label}
        {required && <span className={styles['required']}>*</span>}
      </label>
      <div className={styles['numberInput']}>
        <input
          value={value}
          type="password"
          placeholder={placeholder}
          className={styles['input'] + ' input-text'}
          {...inputProps}
          onChange={(e) => handleChange(e)}
          style={style}
          disabled={disabled}
        />
      </div>
      {error && <p className={styles['error-text']}>{error}</p>}
    </div>
  );
};

export default PasswordInput;
