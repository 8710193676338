import AddessForm from "../../components/addressForm";
import GenderInput from "../../components/genderInput";
import SimpleInput from "../../components/simpleInput";
import ChildAddress from "./childAddress";
// import { Formik, Form, Field, ErrorMessage } from "formik";

const ChildForm = ({
  sequence,
  values,
  setFieldValue,
  errors,
  kid = {},
  touched,
}) => {
  const hasError = (key) => {
    const errch =
      errors.children && errors.children[sequence - 1]
        ? errors.children[sequence - 1]
        : {};
    const errtoc =
      touched.children && touched.children[sequence - 1]
        ? touched.children[sequence - 1]
        : {};
    if (errch[key] && errtoc[key]) {
      return errch[key];
    } else {
      return "";
    }
  };

  return (
    <>
      {/* div for heading  */}
      <div className="row justify-content-center align-items-center mt-5">
        <div className="col-md-6 col-sm-6 col-12">
          <h5>Child-{sequence}</h5>
        </div>
        <div className="col-md-6 col-sm-6 col-12"></div>
      </div>

      <div className="row justify-content-center align-items-center mt-3">
        <div className="col-md-6 col-sm-6 col-12">
          <SimpleInput
            label={`Child-${sequence} First Name`}
            placeholder={`Enter Child-${sequence} first name`}
            onChange={(e) => {
              let temp = values.children;
              temp[sequence - 1].first_name = e.target.value;
              setFieldValue("children", temp);
            }}
            required
            value={kid.first_name}
            error={hasError("first_name")}
          />
        </div>
        <div className="col-md-6 col-sm-6 col-12">
          <SimpleInput
            label={`Child-${sequence} Middle Name`}
            placeholder={`Enter Child-${sequence} middle name`}
            onChange={(e) => {
              let temp = [...values.children];
              temp[sequence - 1].middle_name = e.target.value;
              setFieldValue("children", temp);
            }}
            value={kid.middle_name}
          />
        </div>
      </div>
      <div className="row justify-content-center align-items-center mt-3">
        <div className="col-md-6 col-sm-6 col-12">
          <SimpleInput
            label={`Child-${sequence} Last Name`}
            placeholder={`Enter Child-${sequence} last name`}
            onChange={(e) => {
              let temp = values.children;
              temp[sequence - 1].last_name = e.target.value;
              setFieldValue("children", temp);
            }}
            required
            value={kid.last_name}
            error={hasError("last_name")}
          />
        </div>
        <div className="col-md-6 col-sm-6 col-12">
          <SimpleInput
            label={`Child-${sequence} Phone Number`}
            placeholder={`Enter Child-${sequence} phone number`}
            type="number"
            maxLength={"10"}
            onChange={(e) => {
              let temp = [...values.children];
              temp[sequence - 1].phone = e.target.value;
              setFieldValue("children", temp);
            }}
            phoneInput
            value={kid.phone}
          />
        </div>
      </div>

      <div className="row justify-content-center align-items-center mt-4">
        <div className="col-md-6 col-sm-6 col-12">
          <SimpleInput
            label={`Child-${sequence} Email`}
            placeholder={`Enter Child-${sequence} email`}
            type="text"
            onChange={(e) => {
              let temp = [...values.children];
              temp[sequence - 1].email = e.target.value;
              setFieldValue("children", temp);
            }}
            value={kid.email}
          />
        </div>
        <div className="col-md-6 col-sm-6 col-12">
          <SimpleInput
            label={`Child-${sequence} Date of Birth`}
            placeholder={`Enter Child-${sequence} date of birth`}
            type="date"
            onChange={(e) => {
              let temp = [...values.children];
              temp[sequence - 1].dob = e.target.value;
              setFieldValue("children", temp);
            }}
            value={kid.dob}
            required
            error={hasError("dob")}
          />
        </div>
      </div>
      <div className="row justify-content-center align-items-center mt-4">
        <div className="col-md-6 col-sm-6 col-12">
          <GenderInput
            value={kid.gender}
            onChange={(e) => {
              let temp = values.children;
              temp[sequence - 1].gender = e;
              setFieldValue("children", temp);
            }}
            key="gender"
            label={"Select gender"}
            required
            error={hasError("gender")}
          />
        </div>
        <div className="col-md-6 col-sm-6 col-12"></div>
      </div>
      <ChildAddress
        showCheckBox={true}
        setFieldValue={setFieldValue}
        values={values}
        errors={errors}
        addressKey={`children[${sequence - 1}].address`}
        sequence={sequence - 1}
        touched={touched}
      />
    </>
  );
};

export default ChildForm;
