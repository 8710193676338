import React from 'react';

const SimpleButton = ({
  title = '',
  style = {},
  color = 'primary',
  onClick = () => {},
  type = '',
  ref,
  disabled,
}) => {
  return (
    <button
      ref={ref}
      type={type}
      className="min-w-40 h-9 px-4 bg-[#515fed] hover:bg-[#3C405E] font-medium  text-[#fff] transition duration-150 rounded-lg text-md"
      style={style}
      onClick={onClick}
      disabled={disabled}
      color={color}
    >
      {title}
    </button>
  );
};
export default SimpleButton;
