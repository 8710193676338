import "./style.css";

const CheckBox = ({ label, checked, onChange = () => {}, error }) => (
  <>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div className="form-check">
        <input
          type="checkbox"
          className="form-check-input"
          checked={checked}
          onChange={onChange}
          style={{
            backgroundColor: checked ? "#7450BE" : "",
          }}
        />
        <label className="form-check-label mobile-font">{label}</label>
      </div>

      <span
        style={{
          fontSize: "12px",
          height: "10px",
        }}
        className="mt-2 text-danger mobile-font"
      >
        {error}
      </span>
    </div>
  </>
);

export default CheckBox;
