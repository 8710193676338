import React, { useState } from "react";
import CustomTable from "../../components/table";
import "./styles.css";
import usePostQuery from "../../hooks/postQuery.hook";
import apiUrls from "../../apis/apis";
const FamilyList = () => {
  const [userType, setUserType] = useState("regular");

  // const handleSize = async () => {
  //   await patchQuery({
  //     url: apiUrls.updateStatus,
  //     patchData: {
  //       _id: "650d7b855c07086971ac23e6",
  //       tshirt_size: "S",
  //     },
  //     onSuccess: (result) => {
  //       console.log(result);
  //     },
  //     onFail: (err) => {
  //       console.log(err);
  //     },
  //   });
  // };
  const regularUserColumns = [
    {
      Header: "Name",
      accessor: "Name",
    },
    {
      Header: "Description",
      accessor: "description",
    },
    {
      Header: "T-shirt Size",
      accessor: "tShirtSize",
      Cell: ({ row }) => {
        return (
          <>
            <select className="dropdown">
              <option value="M">M</option>
              <option value="S">S</option>
              <option value="XL">XL</option>
            </select>
            <button className="btnSub">Submit</button>
          </>
        );
      },
    },
  ];

  const adminUserColumns = [
    {
      Header: "Name",
      accessor: "Name",
    },
    {
      Header: "Description",
      accessor: "description",
    },
  ];

  const columns =
    userType === "regular" ? regularUserColumns : adminUserColumns;

  const data = [
    {
      Name: "Dummy Title 1",
      description: "This is a dummy description 1",
    },
    {
      Name: "Dummy Title 2",
      description: "This is a dummy description 2",
    },
    {
      title: "Dummy Title 3",
      description: "This is a dummy description 3",
    },
  ];

  return (
    <div>
      <CustomTable data={data} columns={columns} />
    </div>
  );
};

export default FamilyList;
