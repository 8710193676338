import React from "react";

const TableHeaderComponent = ({ headerGroup }) => {
  return (
    <>
      <tr {...headerGroup.getHeaderGroupProps()}>
        {headerGroup.headers.map((column) => (
          <th
            className="flex-row align-items-center justify-content-between"
            style={{ backgroundColor: "#000" }}
            {...column.getHeaderProps(column.getSortByToggleProps())}
          >
            {column.render("Header")}
            {/* <span>
              {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
            </span> */}
          </th>
        ))}
      </tr>
      <tr {...headerGroup.getHeaderGroupProps()}>
        {headerGroup.headers.map((column) => (
          <>
            <th
              {...column.getHeaderProps()}
              style={{ backgroundColor: "#000" }}
            >
              <div>{column.canFilter ? column.render("Filter") : null}</div>
            </th>
          </>
        ))}
        <th></th>
      </tr>
    </>
  );
};

export default TableHeaderComponent;
