import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./index.module.scss";
import { images } from "../../assets/images";
import { HiMenu } from "react-icons/hi";
import { IoClose } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { RESET_FORM, SET_USER } from "../../redux/actions";
import usePostQuery from "../../hooks/postQuery.hook";
import apiUrls from "../../apis/apis";

const Header = () => {
  const dispatch = useDispatch();
  const { _id } = useSelector(
    (state) => state?.commonReducer?.userDetails || {}
  );
  const { mobile_number } = useSelector(
    (state) => state?.commonReducer?.userDetails.user || {}
  );

  console.log(mobile_number);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const handleLogout = () => {
    dispatch({
      type: SET_USER,
      payload: {},
    });
    localStorage.removeItem("userDetails");
    localStorage.clear();
    navigate("/");
  };

  const { postQuery, loading } = usePostQuery();

  const fetchedNomination = async () => {
    await postQuery({
      url: apiUrls.getNomination,
      postData: { user_id: _id },
      onSuccess: (result) => {
        navigate("/edit-nomination", {
          state: { data: result.nomination },
        });
      },
      onFail: () => {
        navigate("/nomination");
      },
    });
  };

  return (
    <header className={styles["headerContainer"]}>
      <div className={styles["logoContainer"]}>
        <a href="/">
          <img src={images.logo} alt="logo" />
        </a>
      </div>
      {_id ? (
        mobile_number === "1111111111" ? (
          <ul className={styles["desktopMenuContainer"]}>
            <li
              className={styles["link"] + " font-1-bold"}
              onClick={() => {
                localStorage.removeItem("pageIndex");
                navigate("/user");
              }}
            >
              Users
            </li>
            <li
              className={styles["link"] + " font-1-bold"}
              onClick={() => {
                localStorage.removeItem("pageIndex");
                navigate("/all-nominations");
              }}
            >
              Nominations
            </li>
            <li>
              <button
                onClick={() => handleLogout()}
                className={styles["btn"] + " buttonText"}
              >
                Logout
              </button>
            </li>
          </ul>
        ) : (
          <ul className={styles["desktopMenuContainer"]}>
            <li
              className={styles["link"] + " font-1-bold"}
              // onClick={fetchedNomination}
            >
              Presidents
            </li>
            <li
              className={styles["link"] + " font-1-bold"}
              // onClick={fetchedNomination}
            >
              Trustees
            </li>
            <li
              className={styles["link"] + " font-1-bold"}
              onClick={fetchedNomination}
            >
              BoD & BoT Nominations
            </li>
            <li>
              <button
                onClick={() => handleLogout()}
                className={styles["btn"] + " buttonText"}
              >
                Logout
              </button>
            </li>
          </ul>
        )
      ) : (
        <ul className={styles["desktopMenuContainer"]}>
          <li
            className={styles["link"] + " font-1-bold"}
            onClick={() => {
              navigate("/login");
            }}
          >
            Login
          </li>
          <li>
            <a
              href="/register"
              onClick={() => {
                dispatch({
                  type: RESET_FORM,
                  payload: true,
                });
              }}
            >
              <button className={styles["btn"] + " buttonText"}>
                Become A Member
              </button>
            </a>
          </li>
        </ul>
      )}
      <div className={styles["mobileMenu"]}>
        <HiMenu size={30} onClick={() => setIsOpen(true)} />
        {_id ? (
          mobile_number === "1111111111" ? (
            <ul
              className={`${styles["mobileMenuContainer"]} ${
                isOpen ? styles["menuActive"] : styles["menuInactive"]
              }`}
            >
              <li className={styles["link"] + " font-1-bold"}>
                <IoClose size={30} onClick={() => setIsOpen(false)} />
              </li>
              <li
                className={styles["link"] + " font-1-bold"}
                onClick={() => {
                  localStorage.removeItem("pageIndex");
                  navigate("/user");
                }}
              >
                Users
              </li>
              <li
                className={styles["link"] + " font-1-bold"}
                onClick={() => {
                  localStorage.removeItem("pageIndex");
                  navigate("/all-nominations");
                }}
              >
                Nominations
              </li>
              <li>
                <button
                  onClick={handleLogout}
                  className={styles["btn"] + " buttonText"}
                >
                  Logout
                </button>
              </li>
            </ul>
          ) : (
            <ul
              className={`${styles["mobileMenuContainer"]} ${
                isOpen ? styles["menuActive"] : styles["menuInactive"]
              }`}
            >
              <li className={styles["link"] + " font-1-bold"}>
                <IoClose size={30} onClick={() => setIsOpen(false)} />
              </li>
              <li
                className={styles["link"] + " font-1-bold"}
                // onClick={fetchedNomination}
              >
                Presidents
              </li>
              <li
                className={styles["link"] + " font-1-bold"}
                // onClick={fetchedNomination}
              >
                Trustees
              </li>
              <li
                className={styles["link"] + " font-1-bold"}
                onClick={fetchedNomination}
              >
                BoD & BoT Nominations
              </li>
              <li>
                <button
                  onClick={handleLogout}
                  className={styles["btn"] + " buttonText"}
                >
                  Logout
                </button>
              </li>
            </ul>
          )
        ) : (
          <ul
            className={`${styles["mobileMenuContainer"]} ${
              isOpen ? styles["menuActive"] : styles["menuInactive"]
            }`}
          >
            <li className={styles["link"] + " font-1-bold"}>
              <IoClose size={30} onClick={() => setIsOpen(false)} />
            </li>
            <li
              onClick={() => {
                navigate("/login");
              }}
              className={styles["link"] + " font-1-bold"}
            >
              Login
            </li>
            <li
              onClick={() => {
                dispatch({
                  type: RESET_FORM,
                  payload: true,
                });
                navigate("/register");
              }}
            >
              <button className={styles["btn"] + " buttonText"}>
                Become A Member
              </button>
            </li>
          </ul>
        )}
      </div>
    </header>
  );
};

export default Header;
