import React from "react";
import { ArrowRight } from "react-bootstrap-icons";

function Button({
  className = "",
  title,
  onClick = () => {},
  style = {},
  type = "button",
  showArrow = true,
  disabled = false,
}) {
  return (
    <button
      disabled={disabled}
      className={`btn rounded text-center font-medium inline-flex items-center ${className}`}
      type={type}
      style={{
        width: "7.5rem",
        height: "3rem",
        backgroundColor: "#515fed",
        color: "white",
        textAlign: "center",
        ...style,
      }}
      onClick={onClick}
    >
      {title} {showArrow ? <ArrowRight /> : null}
    </button>
  );
}

export default Button;
