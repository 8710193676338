import * as Yup from "yup";
import { emailRegExp, phoneRegExp, zipcodeRegExp } from "../utils/regex";

const step2Validations = Yup.object().shape({
  no_spouse: Yup.boolean().required("This field is required"),
  first_name: Yup.string().when("no_spouse", {
    is: false,
    then: () => Yup.string().required("This field is required"),
    otherwise: () => Yup.string(),
  }),
  middle_name: Yup.string(),
  last_name: Yup.string().when("no_spouse", {
    is: false,
    then: () => Yup.string().required("This field is required"),
    otherwise: () => Yup.string(),
  }),
  spouse_phone: Yup.string().when("no_spouse", {
    is: false,
    then: () => Yup.string().matches(phoneRegExp, "Invalid Phone"),
    otherwise: () => Yup.string(),
  }),
  spouse_email: Yup.string().when("no_spouse", {
    is: false,
    then: () => Yup.string().matches(emailRegExp, "Invalid email"),
    otherwise: () => Yup.string(),
  }),
  spouse_dob: Yup.date().when("no_spouse", {
    is: false,
    then: () => Yup.date().required("This field is required"),
    otherwise: () => Yup.date(),
  }),
  spouse_address: Yup.object().when("no_spouse", {
    is: false,
    then: () =>
      Yup.object()
        .shape({
          street: Yup.string().required("This field is required"),
          appartment: Yup.string(),
          city: Yup.string().required("This field is required"),
          state: Yup.string().required("This field is required"),
          zipcode: Yup.string().required("This field is required"),
        })
        .required("This field is required"),
    otherwise: () => Yup.object(),
  }),
});

export default step2Validations;
