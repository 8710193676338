import "./stepper.css";
const StepperHeader = ({ active = false, label, step }) => {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: active ? "#7450BE" : "rgba(116, 80, 190, 0.05)",
      }}
    >
      <div
      className="pb-4"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
      >
        <h5
          className="index"
          style={{
            marginTop: "10px",
            color: active ? "#f0ecf8" : "#7450BE",
          }}
        >
          <b> {step}</b>
        </h5>
        <h6
          className="stepperTitle"
          style={{
            color: active ? "#f0ecf8" : "#7450BE",
            fontWeight: "bold",
          }}
        >
          {label}
        </h6>
      </div>
    </div>
  );
};

export default StepperHeader;
