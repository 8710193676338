import React from "react";
import Header from "../../components/header";
import { images } from "../../assets/images";
import styles from "./index.module.scss";
import { icons } from "../../assets/icons";
import { useNavigate } from "react-router-dom";
import { HiSpeakerphone } from "react-icons/hi";

const Home = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Header />
      <section className={styles["heroSection"] + " mainContainer"}>
        <div className={styles["leftContainer"]}>
          <p style={{ color: "#101f41" }} className="font-1-bold">
            Kachhiya Patidar Samaj Of USA
          </p>
          <h1 className="mainHeading">The Kachhiya Patidar Samaj Of USA</h1>
          <p className="subHeading">
            The Kachhiya Patidar Samaj of USA is the community of people of the
            Kachhiya Samaj immigrated to the USA from the state of Gujarat in
            the great country of Bharat/India.
          </p>
          <p className="subHeading">
            This portal is in making and we have published the page for the
            detailed information on the KPS Convention 2024, check
            out the details.
          </p>
          {/* <div className={styles["counterContainer"]}>
            <div className={styles["counter"]}>
              <div className={styles["counterImage"]}>
                <img src={icons.user} alt="user_icon" />
              </div>
              <p className={styles["count"] + " heading"}>7435</p>
              <p className="font-3">Active Users</p>
            </div>
            <div className={styles["counter"]}>
              <div className={styles["counterImage"]}>
                <img src={icons.speaker} alt="user_icon" />
              </div>
              <p className={styles["count"] + " heading"}>7435</p>
              <p className="font-3">Campaign Posted</p>
            </div>
            <div className={styles["counter"]}>
              <div className={styles["counterImage"]}>
                <img src={icons.groupUser} alt="user_icon" />
              </div>
              <p className={styles["count"] + " heading"}>7435</p>
              <p className="font-3">User's Visited</p>
            </div>
          </div> */}
          <div className={styles["btnContainer"]}>
            <button
              onClick={() => navigate("/events")}
              className={styles["btn"]}
            >
              <HiSpeakerphone size={24} />
              KPS Convention 2024 Details{" "}
            </button>
          </div>
        </div>
        <div className={styles["rightContainer"]}>
          <img src={images.heroImage} alt="" />
        </div>
      </section>
    </div>
  );
};

export default Home;
