import { useNavigate } from "react-router-dom";
import ChildForm from "./childernForm";
import Button from "../../components/buttton";
import { Form, Formik } from "formik";
import NumberOfChildern from "./numberOfChildern";
import step4Validations from "./validation";
import usePostQuery from "../../hooks/postQuery.hook";
import apiUrls from "../../apis/apis";
import { toast } from "react-toastify";
import LoadingSpinner from "../../components/loader";
import { useEffect } from "react";

// import { Formik, Form, Field, ErrorMessage } from "formik";

const Step4 = ({ setActiveStep, setScrollTop }) => {
  useEffect(() => {
    setScrollTop(true);
    setTimeout(() => {
      setScrollTop(false);
    }, 1000);
  }, []);
  const navigate = useNavigate();
  const localStorageValues = JSON.parse(localStorage.getItem("step1"));
  let initialValues = {};
  const { postQuery, loading } = usePostQuery();
  if (localStorageValues && localStorageValues.children_details) {
    initialValues = localStorageValues.children_details;
  } else {
    initialValues = {
      no_of_children: 1,
      children: [
        {
          first_name: "",
          middle_name: "",
          last_name: "",
          phone: "",
          email: "",
          dob: "",
          gender: "",
          address:
            localStorageValues &&
            localStorageValues.user &&
            localStorageValues.user.address
              ? localStorageValues.user.address
              : {
                  street: "",
                  appartement: "",
                  city: "",
                  state: "",
                  zipcode: "",
                },
        },
      ],
    };
  }
  return (
    <>
      <LoadingSpinner loading={loading} />
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          const array = [];
          values?.children.forEach(function (obj) {
            if (obj.phone) {
              array?.push(obj.phone);
            }
          });
          const array2 = [];
          values?.children.forEach(function (obj) {
            if (obj.email) {
              array?.push(obj.email);
            }
          });

          const phones = [...array];
          if (localStorageValues.user.phone) {
            phones.push(localStorageValues.user.phone);
          }
          if (localStorageValues?.spouce_details?.spouse_phone) {
            phones.push(localStorageValues?.spouce_details.spouse_phone);
          }
          if (localStorageValues?.father_details?.phone) {
            phones.push(localStorageValues?.father_details?.phone);
          }
          if (localStorageValues?.mother_details?.phone) {
            phones.push(localStorageValues?.mother_details?.phone);
          }

          const emails = [...array2];
          if (localStorageValues.user.email) {
            emails.push(localStorageValues.user.email);
          }
          if (localStorageValues?.spouce_details.spouse_email) {
            emails.push(localStorageValues?.spouce_details.spouse_email);
          }
          if (localStorageValues?.father_details?.email) {
            emails.push(localStorageValues?.father_details?.email);
          }
          if (localStorageValues?.mother_details?.email) {
            emails.push(localStorageValues?.mother_details?.email);
          }

          postQuery({
            url: apiUrls.verifyMobileAndEmail,
            postData: {
              mobile_number: phones,
              email: emails,
            },
            onSuccess: () => {
              localStorage.setItem(
                "step1",
                JSON.stringify({
                  ...localStorageValues,
                  children_details: { ...values },
                })
              );
              postQuery({
                url: apiUrls.createKpsPersons,
                postData: {
                  ...localStorageValues,
                  children_details: { ...values },
                },
                onSuccess: (data) => {
                  toast.success("Data saved successfully");
                  console.log(data, "data from step4 response");
                  localStorage.removeItem("step1");
                  navigate("/events");
                },
                onError: (error) => {
                  console.log(error, "error");
                },
              });
              // setActiveStep(3);
            },
            onFail: () => {
              toast.error("Email or Mobile already exist");
            },
          });
        }}
        validationSchema={step4Validations}
      >
        {({ values, errors, touched, setFieldValue, submitForm }) => {
          return (
            <Form>
              <div className="row justify-content-center align-items-center mt-5">
                <div className="col-md-6 col-sm-6 col-12">
                  <NumberOfChildern
                    label="How many children?"
                    value={values.no_of_children}
                    onChange={(e) => {
                      setFieldValue("no_of_children", e);
                      let temp = values.children;
                      if (values.children.length > parseInt(e)) {
                        temp = values.children.slice(0, parseInt(e));
                        setFieldValue("children", temp);
                      } else {
                        let from = values.children.length;
                        for (let i = from; i < e; i++) {
                          temp.push({
                            first_name: "",
                            middle_name: "",
                            last_name: "",
                            phone: "",
                            email: "",
                            dob: "",
                            gender: "",
                            address:
                              localStorageValues &&
                              localStorageValues.user &&
                              localStorageValues.user.address
                                ? localStorageValues.user.address
                                : {
                                    street: "",
                                    appartement: "",
                                    city: "",
                                    state: "",
                                    zipcode: "",
                                  },
                          });
                        }
                      }
                    }}
                    required
                    error={
                      touched.no_of_children && errors.no_of_children
                        ? errors.no_of_children
                        : null
                    }
                  />
                </div>
                <div className="col-md-6 col-sm-6 col-12"></div>
              </div>

              {values.children.map((item, index) => {
                return (
                  <ChildForm
                    sequence={index + 1}
                    values={values}
                    setFieldValue={setFieldValue}
                    kid={values.children[index]}
                    errors={errors}
                    touched={touched}
                  />
                );
              })}
              <div className="d-flex row justify-content-end align-items-center mt-3">
                <div className="col-md-6 col-sm-6 col-6">
                  <div
                    className="col-md-6 col-sm-6 col-6"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start",
                      alignItems: "center",
                      marginLeft: "30px",
                    }}
                  >
                    <a
                      onClick={() => {
                        setActiveStep(3);
                      }}
                      style={{
                        textDecoration: "none",
                        color: "#7450BE",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        cursor: "pointer",
                      }}
                    >
                      <h6>Back</h6>
                    </a>
                  </div>
                </div>
                <div
                  className="col-md-6 col-sm-6 col-6"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                >
                  <Button
                    title="Next"
                    onClick={submitForm}
                    style={{
                      marginRight: "10px",
                    }}
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default Step4;
