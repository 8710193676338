import React, { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { images } from "../../assets/images";
import "./index.css";
import SimpleButton from "../../components/simpleButton";
import usePostQuery from "../../hooks/postQuery.hook";
import apiUrls from "../../apis/apis";
import LoadingSpinner from "../../components/loader";
import { useDispatch } from "react-redux";
import { SET_USER } from "../../redux/actions";
import PasswordInput from "../../components/passwordInput";

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [phone_number, setPhone_number] = useState("");
  const [isPassword, setIspassword] = useState(false);
  const [password, setPassword] = useState("");
  const { postQuery } = usePostQuery();
  const dispatch = useDispatch();

  const handleLogin = async (e) => {
    e.preventDefault();
    console.log("alkhclknsd");
    if (phone_number && !password) {
      setLoading(true);
      postQuery({
        url: apiUrls.sendOtp,
        postData: {
          mobile_number: phone_number,
        },
        onSuccess: (data) => {
          console.log("==================", data);
          setLoading(false);
          if (!data.success) {
            return toast.error("Approval is pending");
          }
          if (!data.password) {
            return navigate("/create-password", { state: { phone_number } });
          }
          setIspassword(data.password);
        },
        onFail: (error) => {
          setLoading(false);
          console.log(error, "errcsdcdscdscor");
          toast.error(error?.response?.data?.message);
        },
      });
    }
    if (phone_number && password) {
      setLoading(true);
      postQuery({
        url: apiUrls.login,
        postData: {
          mobile_number: phone_number,
          password: password,
        },
        onSuccess: (data) => {
          setLoading(false);
          // toast.success('Verification Successfully Done');
          localStorage.setItem(
            "userDetails",
            JSON.stringify({
              _id: data?.user?._id,
              family_id: data?.user?.family_id,
              token: data?.token,
              user: data?.user,
            })
          );
          dispatch({
            type: SET_USER,
            payload: {
              _id: data?.user?._id,
              family_id: data?.user?.family_id,
              token: data?.token,
              user: data?.user,
            },
          });
          if (data?.user?.mobile_number === "1111111111") {
            navigate("/");
          } else {
            navigate("/events");
          }
        },
        onFail: (error) => {
          setLoading(false);
          toast.error("Invalid Credentials");
          console.log(error, "error");
        },
      });
      setLoading(false);
    }
  };

  return (
    <>
      <div className="main-container bg-[#515fed]">
        <LoadingSpinner loading={loading} />
        <div className="flex items-center justify-center h-screen">
          <div className="md:w-[60%] w-[90%] md:h-[60%] h-[35%] bg-[#d3d3d3] grid md:grid-cols-2 z-10 rounded">
            <div className="flex justify-center items-center w-full md:border-r-[1px] border-gray-400">
              <div className="px-10">
                <div className="mb-3 ">
                  <h1 className="my-3 text-4xl font-bold text-[#3C405E]">
                    Login
                  </h1>
                  <p className="text-sm text-[#3C405E]">
                    Sign in to your account
                  </p>
                </div>
                <form onSubmit={handleLogin} className="space-y-12">
                  <div className="space-y-4">
                    <div className="flex justify-start items-center">
                      <div className="flex justify-between">
                        <label
                          htmlFor="phone_number"
                          className="w-10 bg-[#bcbcbc] h-10 rounded-l-md border-[1px] border-gray-400 border-opacity-60 flex justify-center items-center"
                        >
                          +1
                        </label>
                      </div>
                      <input
                        disabled={isPassword}
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 10);
                        }}
                        type="number"
                        name="Phone Number"
                        id="phone_number"
                        placeholder="Phone Number"
                        onChange={(e) => setPhone_number(e.target.value)}
                        className="w-full px-3 py-2 rounded-r-md focus:outline-1 outline-slate-400 border-[1px] border-gray-400 border-opacity-60"
                        data-temp-mail-org="0"
                      />
                    </div>
                    {!isPassword ? (
                      <div className="mt-3 flex justify-center">
                        <SimpleButton
                          style={{ width: "120px" }}
                          type="submit"
                          title="Login"
                        />
                      </div>
                    ) : (
                      <div className="OTP_component">
                        <PasswordInput
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <div className="mt-3 flex justify-center">
                          <SimpleButton
                            style={{ width: "120px" }}
                            type="submit"
                            title="Verify"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
            <div className="p-10 hidden md:flex justify-center items-center flex-col">
              <img
                style={{
                  width: "20rem",
                  height: "6rem",
                }}
                src={images.transparentLogo}
                alt="Logo"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
