import * as yup from "yup";
import { emailRegExp, phoneRegExp, zipcodeRegExp } from "../utils/regex";

const step1Validations = yup.object().shape({
  first_name: yup.string().required("Name is required"),
  middle_name: yup.string(),
  last_name: yup.string().required("Name is required"),
  phone: yup
    .string()
    .matches(phoneRegExp, "Invalid phone")
    .required("This field is required"),
  dob: yup.date().required("This field is required"),
  email: yup
    .string()
    .matches(emailRegExp, "Invalid email")
    .required("This field is required"),
  gender: yup.string().required("This field is required"),
  address: yup.object().shape({
    street: yup.string().required("This field is required"),
    appartment: yup.string(),
    city: yup.string().required("CThis field is required"),
    state: yup.string().required("This field is required"),
    zipcode: yup.string().required("This field is required"),
  }),
  town: yup.string().required("This field is required"),
});

export default step1Validations;
