import React from "react";
import Header from "../../components/header";
import styles from "./index.module.scss";
import { images } from "../../assets/images";
import BannerSlider from "../../components/bannerSlider";
import TShirtSize from "../tshirtSize";
import { useSelector } from "react-redux";

const Events = () => {
  const { userDetails: { _id } = {} } = useSelector(
    (state) => state.commonReducer
  );
  return (
    <>
      <Header />
      <BannerSlider />
      <section
        className={`${
          _id ? styles["eventsSection"] : styles["eventsSectionSingle"]
        } mainContainer`}
      >
        <div className={styles["leftContainer"]}>
          <p className="heading">
            Get on the Cruise for celebrating The KPS Convention 2024!
          </p>
          <p className={styles["description"]}>
            <strong>President's Message for KPS Convention 2024:</strong> <br />{" "}
            I'm proud to announce to celebrate the 30th anniversary of our Samaj
            we will have our KPS 2024 Convention on Carnival Horizon from July
            20-28, 2024
          </p>
          <p className={styles["description"]}>
            <strong>Itinerary:</strong> <br />
            <ul>
              <li>
                Day 1: July 20, 2024:{" "}
                <span
                  style={{ fontWeight: "600" }}
                  className={styles["description"]}
                >
                  Miami
                </span>
              </li>
              <li>
                Day 2 & 3: July 21 & 22, 2024:
                <span
                  style={{ fontWeight: "600" }}
                  className={styles["description"]}
                >
                  At Sea
                </span>
              </li>
              <li>
                Day 4: July 23, 2024:{" "}
                <span
                  style={{ fontWeight: "600" }}
                  className={styles["description"]}
                >
                  Aruba
                </span>
              </li>
              <li>
                Day 5: July 24, 2024:{" "}
                <span
                  style={{ fontWeight: "600" }}
                  className={styles["description"]}
                >
                  Bonaire
                </span>
              </li>
              <li>
                Day 6: July 25, 2024:{" "}
                <span
                  style={{ fontWeight: "600" }}
                  className={styles["description"]}
                >
                  La Romana
                </span>
              </li>
              <li>
                Day 7: July 26, 2024:{" "}
                <span
                  style={{ fontWeight: "600" }}
                  className={styles["description"]}
                >
                  Amber Cove
                </span>
              </li>
              <li>
                Day 8: July 27, 2024:{" "}
                <span
                  style={{ fontWeight: "600" }}
                  className={styles["description"]}
                >
                  At Sea
                </span>
              </li>
              <li>
                Day 9: July 28, 2024:{" "}
                <span
                  style={{ fontWeight: "600" }}
                  className={styles["description"]}
                >
                  Miami
                </span>
              </li>
            </ul>
          </p>
          <p className={styles["description"]}>
            As we are{" "}
            <span
              style={{ fontWeight: "600" }}
              className={styles["description"]}
            >
              very very late
            </span>{" "}
            to book this convention on a cruise,{" "}
            <span className="bold">we need to act fast</span>. Space is limited.
            If you aren't sure, then just book for now and cancel later for FREE
            before a certain date. You MUST book through our group and agent.
            You must use the below group booking #. It will benefit you and our
            whole group.
          </p>
          <p className={styles["description"]}>
            Leave Dee a voicemail and she'll call u back within 3 to 4 days. He
            call will be a 305 number so make sure u pick up her call.
            <br />
            <br />
            Group booking # is <strong>X9T0K6</strong>
          </p>
          <p className={styles["description"]}>
            Our agent contact is: <br />
            Dee Negrin <br />
            800.819.3902 ext 82367 <br />
            Dnegrin@carnival.com
          </p>
          <p className={styles["description"]}>
            Thank you <br />
            Kalpesh Ram <br />
            President
          </p>
        </div>
        {_id && (
          <div className={styles["rightContainer"]}>
            <TShirtSize />
          </div>
        )}
      </section>
    </>
  );
};

export default Events;
