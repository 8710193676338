import React, { useEffect, useState } from "react";
import CustomTable from "../../components/table";
import useGetQuery from "../../hooks/getQuery.hook";
import apiUrls from "../../apis/apis";
import usePostQuery from "../../hooks/postQuery.hook";
import usePatchQuery from "../../hooks/patchQuery.hook";
import axios from "axios";
import Loader from "../../components/loader";
import Header from "../../components/header";
import moment from "moment";
import "./style.css";

const Nominations = () => {
  const [userType, setUserType] = useState("regular");
  const [rowStatus, setRowStatus] = useState("");
  const [tableData, setTableData] = useState([]);

  console.log("table ", tableData);
  const { getQuery, data, loading } = useGetQuery();
  const fetchUserData = async () => {
    try {
      await getQuery({
        url: apiUrls.getAllNominations,
        postData: {},
        onSuccess: (response) => {
          setTableData(response.nomination || []);
        },
      });
    } catch (error) {
      console.log(error, "error");
    }
  };
  useEffect(() => {
    fetchUserData();
  }, []);

  //   const handleStatus = async (id, status) => {
  //     try {
  //       const response = await postQuery({
  //         url: apiUrls.updateStatus,
  //         postData: {
  //           _id: id,
  //           status: status,
  //         },
  //       });
  //       console.log(response);
  //       fetchUserData();
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };

  const columns = [
    {
      Header: "Name",
      // accessor: "name",
      Cell: ({ row }) => {
        if (row?.original?.user?.name) {
          return (
            <>
              <div
                style={{
                  width: "200px",
                }}
              >
                {row?.original?.user?.name || "-"}
              </div>
            </>
          );
        } else {
          if (row?.original?.user?.middle_name) {
            return (
              <>
                <div
                  style={{
                    width: "200px",
                  }}
                >
                  {row?.original?.user?.first_name +
                    " " +
                    row?.original?.user?.middle_name +
                    " " +
                    row?.original?.user?.last_name}
                </div>
              </>
            );
          } else {
            return (
              <>
                <div>
                  {row?.original?.user?.first_name +
                    " " +
                    row?.original?.user?.last_name}
                </div>
              </>
            );
          }
        }
      },
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: ({ row }) => {
        return (
          <>
            <div>{row?.original?.user?.email || "-"}</div>
          </>
        );
      },
    },
    {
      Header: "Mobile Number",
      accessor: "mobile_number",
      Cell: ({ row }) => {
        return (
          <>
            <div>{row?.original?.user?.mobile_number || "-"}</div>
          </>
        );
      },
    },
    {
      Header: "Date of birth",
      accessor: "dob",
      Cell: ({ row }) => {
        return (
          <>
            <div>
              {row?.original?.user?.dob
                ? moment(row?.original?.user?.dob).format("MM/DD/YYYY")
                : ""}
            </div>
          </>
        );
      },
    },
    {
      Header: "Gender",
      accessor: "gender",
      Cell: ({ row }) => {
        return (
          <>
            <div>{row?.original?.user?.gender || "-"}</div>
          </>
        );
      },
    },
    {
      Header: "Position",
      accessor: "tshirt_size",
      Cell: ({ row }) => {
        return (
          <>
            <div>{row?.original?.position || "-"}</div>
          </>
        );
      },
    },
  ];

  console.log("tableData", tableData);

  return (
    <div>
      <Header />
      <Loader loading={loading} />
      <CustomTable data={tableData} columns={columns} userType={userType} />
    </div>
  );
};

export default Nominations;
