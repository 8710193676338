import { useNavigate } from "react-router-dom";
import Card from "../../components/card";
import LayOut from "../../components/layout";
import SimpleInput from "../../components/simpleInput";
import Button from "../../components/buttton";
import CheckBox from "../../components/checkbox";
import { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import AddessForm from "../../components/addressForm";
import step3Validations from "./validation";
import usePostQuery from "../../hooks/postQuery.hook";
import apiUrls from "../../apis/apis";
import LoadingSpinner from "../../components/loader";
import { toast } from "react-toastify";

const Step3 = ({ setActiveStep, setScrollTop, skipStep4 }) => {
  useEffect(() => {
    setScrollTop(true);
    setTimeout(() => {
      setScrollTop(false);
    }, 1000);
  }, []);
  const navigate = useNavigate();
  const [deceased, setDeceased] = useState(false);
  const { postQuery, loading } = usePostQuery();
  const localStorageValues = JSON.parse(localStorage.getItem("step1"));
  let initialValues = {};
  if (
    localStorageValues &&
    localStorageValues.father_details &&
    localStorageValues.mother_details
  ) {
    const { father_details, mother_details } = localStorageValues;
    initialValues = {
      father_first_name: father_details.first_name,
      father_middle_name: father_details.middle_name,
      father_last_name: father_details.last_name,
      father_deceased: father_details.deceased,
      father_phone: father_details.phone,
      father_email: father_details.email,
      father_dob: father_details.dob,
      father_address: father_details.address,
      mother_first_name: mother_details.first_name,
      mother_middle_name: mother_details.middle_name,
      mother_last_name: mother_details.last_name,
      mother_deceased: mother_details.deceased,
      mother_phone: mother_details.phone,
      mother_email: mother_details.email,
      mother_dob: mother_details.dob,
      mother_address: mother_details.address,
    };
  } else {
    initialValues = {
      father_first_name: "",
      father_middle_name: "",
      father_last_name: "",
      father_deceased: false,
      father_phone: "",
      father_email: "",
      father_dob: "",
      father_address:
        localStorageValues &&
        localStorageValues.user &&
        localStorageValues.user.address
          ? localStorageValues.user.address
          : {
              street: "",
              appartement: "",
              city: "",
              state: "",
              zipcode: "",
            },
      mother_first_name: "",
      mother_middle_name: "",
      mother_last_name: "",
      mother_deceased: false,
      mother_phone: "",
      mother_email: "",
      mother_dob: "",
      mother_address:
        localStorageValues &&
        localStorageValues.user &&
        localStorageValues.user.address
          ? localStorageValues.user.address
          : {
              street: "",
              appartement: "",
              city: "",
              state: "",
              zipcode: "",
            },
    };
  }

  return (
    <>
      <LoadingSpinner loading={loading} />
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          const phones = [];
          console.log(
            localStorageValues,
            "localStorageValueslocalStorageValues"
          );
          if (localStorageValues.user.phone) {
            phones.push(localStorageValues.user.phone);
          }
          if (localStorageValues?.spouce_details?.spouse_phone) {
            phones.push(localStorageValues?.spouce_details?.spouse_phone);
          }
          if (values?.father_phone) {
            phones.push(values?.father_phone);
          }
          if (values?.mother_phone) {
            phones.push(values?.mother_phone);
          }
          const emails = [];
          if (localStorageValues.user.email) {
            emails.push(localStorageValues.user.email);
          }
          if (localStorageValues?.spouce_details.spouse_email) {
            emails.push(localStorageValues?.spouce_details.spouse_email);
          }
          if (values?.father_email) {
            emails.push(values?.father_email);
          }
          if (values?.mother_email) {
            emails.push(values?.mother_email);
          }
          postQuery({
            url: apiUrls.verifyMobileAndEmail,
            postData: {
              mobile_number: phones,
              email: emails,
            },
            onSuccess: () => {
              localStorage.setItem(
                "step1",
                JSON.stringify({
                  ...localStorageValues,
                  father_details: {
                    first_name: values.father_first_name,
                    middle_name: values.father_middle_name,
                    last_name: values.father_last_name,

                    deceased: values.father_deceased,
                    phone: values.father_phone,
                    email: values.father_email,
                    address: values.father_address,
                    dob: values.father_dob,
                  },
                  mother_details: {
                    first_name: values.mother_first_name,
                    middle_name: values.mother_middle_name,
                    last_name: values.mother_last_name,
                    deceased: values.mother_deceased,
                    phone: values.mother_phone,
                    email: values.mother_email,
                    address: values.mother_address,
                    dob: values.mother_dob,
                  },
                })
              );

              if (skipStep4) {
                postQuery({
                  url: apiUrls.createKpsPersons,
                  postData: {
                    ...localStorageValues,
                    father_details: {
                      first_name: values.father_first_name,
                      middle_name: values.father_middle_name,
                      last_name: values.father_last_name,
                      deceased: values.father_deceased,
                      phone: values.father_phone,
                      email: values.father_email,
                      address: values.father_address,
                      dob: values.father_dob,
                    },
                    mother_details: {
                      first_name: values.mother_first_name,
                      middle_name: values.mother_middle_name,
                      last_name: values.mother_last_name,
                      deceased: values.mother_deceased,
                      phone: values.mother_phone,
                      email: values.mother_email,
                      address: values.mother_address,
                      dob: values.mother_dob,
                    },
                  },
                  onSuccess: () => {
                    localStorage.removeItem("step1");
                    navigate("/events");
                  },
                  onFail: () => {},
                });
              } else {
                setActiveStep(4);
              }
            },
            onFail: () => {
              toast.error("Email or Mobile already exist");
            },
          });
        }}
        validationSchema={step3Validations}
      >
        {({ values, errors, touched, setFieldValue, submitForm }) => {
          return (
            <Form>
              <div className="row justify-content-center align-items-center mt-5">
                <div className="col- col-md-6 sm-12">
                  <h6>Father's Details</h6>
                </div>
                <div className="col-md-6 col-sm-6 col-12"></div>
              </div>
              <div className="row justify-content-center align-items-center mt-3">
                <div className="col-md-6 col-sm-6 col-12">
                  <SimpleInput
                    label="Father's First Name"
                    placeholder="Enter father's first name"
                    onChange={(e) => {
                      setFieldValue("father_first_name", e.target.value);
                    }}
                    value={values.father_first_name}
                    required
                    error={
                      touched.father_first_name && errors.father_first_name
                        ? errors.father_first_name
                        : null
                    }
                  />
                </div>
                <div className="col-md-6 col-sm-6 col-12">
                  <SimpleInput
                    label="Father's Middle Name"
                    placeholder="Enter father's middle name"
                    onChange={(e) => {
                      setFieldValue("father_middle_name", e.target.value);
                    }}
                    value={values.father_middle_name}
                    error={
                      touched.father_middle_name && errors.father_middle_name
                        ? errors.father_middle_name
                        : null
                    }
                  />
                </div>
                <div className="col-md-6 col-sm-6 col-12"></div>
              </div>
              <div className="row justify-content-center align-items-center mt-3">
                <div className="col-md-6 col-sm-6 col-12">
                  <SimpleInput
                    label="Father's Last Name"
                    placeholder="Enter father's last name"
                    onChange={(e) => {
                      setFieldValue("father_last_name", e.target.value);
                    }}
                    value={values.father_last_name}
                    required
                    error={
                      touched.father_last_name && errors.father_last_name
                        ? errors.father_last_name
                        : null
                    }
                  />
                </div>
                <div className="col-md-6 col-sm-6 col-12"></div>
              </div>
              <div className="row justify-content-center align-items-center mt-3">
                <div className="col-md-6 col-sm-6 col-12">
                  <CheckBox
                    label="સ્વર્ગીય નિવાસી / the late"
                    checked={values.father_deceased}
                    onChange={() => {
                      setFieldValue("father_deceased", !values.father_deceased);
                    }}
                    required
                    error={touched.father_deceased && errors.father_deceased}
                  />
                </div>
                <div className="col-md-6 col-sm-6 col-12"></div>
              </div>
              {!values.father_deceased && (
                <>
                  <div className="row justify-content-center align-items-center mt-3">
                    <div className="col-md-6 col-sm-6 col-12">
                      <SimpleInput
                        label="Father's Phone Number"
                        placeholder="Enter father's phone number"
                        type="number"
                        maxLength={"10"}
                        onChange={(e) => {
                          setFieldValue("father_phone", e.target.value);
                        }}
                        value={values.father_phone}
                        phoneInput
                      />
                    </div>
                    <div className="col-md-6 col-sm-6 col-12">
                      <SimpleInput
                        label="Father's Email"
                        placeholder="Enter Father's email"
                        type="text"
                        onChange={(e) => {
                          setFieldValue("father_email", e.target.value);
                        }}
                        value={values.father_email}
                      />
                    </div>
                    <div className="col-md-6 col-sm-6 col-12"></div>
                  </div>
                  <div className="row justify-content-center align-items-center mt-4">
                    <div className="col-md-6 col-sm-6 col-12">
                      <SimpleInput
                        label="Father's Date of Birth"
                        placeholder="Enter father's date of birth"
                        type="date"
                        onChange={(e) => {
                          setFieldValue("father_dob", e.target.value);
                        }}
                        value={values.father_dob}
                        required
                        error={
                          touched.father_dob && errors.father_dob
                            ? errors.father_dob
                            : null
                        }
                      />
                    </div>
                    <div className="col-md-6 col-sm-6 col-12"></div>
                  </div>
                  <AddessForm
                    addressKey="father_address"
                    values={values}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    showCheckBox={true}
                  />
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    className="mt-5"
                  >
                    <div
                      style={{
                        width: "80%",
                        height: "1px",
                        backgroundColor: "#000000",
                      }}
                    ></div>
                  </div>
                </>
              )}
              <div className="row justify-content-center align-items-center mt-5">
                <div className="col-md-6 col-sm-6 col-12">
                  <h6>Mother's Details</h6>
                </div>
                <div className="col-md-6 col-sm-6 col-12"></div>
              </div>
              <div className="row justify-content-center align-items-center mt-3">
                <div className="col-md-6 col-sm-6 col-12">
                  <SimpleInput
                    label="Mother's First Name"
                    placeholder="Enter mother's first name"
                    onChange={(e) => {
                      setFieldValue("mother_first_name", e.target.value);
                    }}
                    value={values.mother_first_name}
                    required
                    error={
                      touched.mother_first_name && errors.mother_first_name
                        ? errors.mother_first_name
                        : null
                    }
                  />
                </div>
                <div className="col-md-6 col-sm-6 col-12">
                  <SimpleInput
                    label="Mother's Middle Name"
                    placeholder="Enter mother's middle name"
                    onChange={(e) => {
                      setFieldValue("mother_middle_name", e.target.value);
                    }}
                    value={values.mother_middle_name}
                    error={
                      touched.mother_middle_name && errors.mother_middle_name
                        ? errors.mother_middle_name
                        : null
                    }
                  />
                </div>
                <div className="col-md-6 col-sm-6 col-12"></div>
              </div>
              <div className="row justify-content-center align-items-center mt-3">
                <div className="col-md-6 col-sm-6 col-12">
                  <SimpleInput
                    label="Mother's Last Name"
                    placeholder="Enter mother's last name"
                    onChange={(e) => {
                      setFieldValue("mother_last_name", e.target.value);
                    }}
                    value={values.mother_last_name}
                    required
                    error={
                      touched.mother_last_name && errors.mother_last_name
                        ? errors.mother_last_name
                        : null
                    }
                  />
                </div>
                <div className="col-md-6 col-sm-6 col-12"></div>
              </div>
              <div className="row justify-content-center align-items-center mt-3">
                <div className="col-md-6 col-sm-6 col-12">
                  <CheckBox
                    label="સ્વર્ગીય નિવાસી / the late"
                    checked={values.mother_deceased}
                    onChange={() => {
                      setFieldValue("mother_deceased", !values.mother_deceased);
                    }}
                    required
                    error={touched.mother_deceased && errors.mother_deceased}
                  />
                </div>
                <div className="col-md-6 col-sm-6 col-12"></div>
              </div>
              {!values.mother_deceased && (
                <>
                  <div className="row justify-content-center align-items-center mt-3">
                    <div className="col-md-6 col-sm-6 col-12">
                      <SimpleInput
                        label="Mother's Phone Number"
                        placeholder="Enter mother's phone number"
                        maxLength={"10"}
                        type="number"
                        phoneInput
                        onChange={(e) => {
                          setFieldValue("mother_phone", e.target.value);
                        }}
                        value={values.mother_phone}
                      />
                    </div>

                    <div className="col-md-6 col-sm-6 col-12">
                      <SimpleInput
                        label="Mother's Email"
                        placeholder="Enter mother's email"
                        type="text"
                        onChange={(e) => {
                          setFieldValue("mother_email", e.target.value);
                        }}
                        value={values.mother_email}
                      />
                    </div>
                  </div>
                  <div className="row justify-content-center align-items-center mt-4">
                    <div className="col-md-6 col-sm-6 col-12">
                      <SimpleInput
                        label="Mother's Date of Birth"
                        placeholder="Enter mother's date of birth"
                        type="date"
                        onChange={(e) => {
                          setFieldValue("mother_dob", e.target.value);
                        }}
                        value={values.mother_dob}
                        required
                        error={
                          touched.mother_dob && errors.mother_dob
                            ? errors.mother_dob
                            : null
                        }
                      />
                    </div>
                    <div className="col-md-6 col-sm-6 col-12"></div>
                  </div>
                  <AddessForm
                    addressKey="mother_address"
                    values={values}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    showCheckBox={true}
                  />
                </>
              )}
              <div className="d-flex row justify-content-end align-items-center mt-4">
                <div className="col-md-6 col-sm-6 col-6">
                  <div
                    className="col-md-6 col-sm-6 col-6"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start",
                      alignItems: "center",
                      marginLeft: "30px",
                    }}
                  >
                    <a
                      onClick={() => {
                        setActiveStep(2);
                      }}
                      style={{
                        textDecoration: "none",
                        color: "#7450BE",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        cursor: "pointer",
                      }}
                    >
                      <h6>Back</h6>
                    </a>
                  </div>
                </div>
                <div
                  className="col-md-6 col-sm-6 col-6"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                >
                  <Button
                    title="Next"
                    onClick={submitForm}
                    style={{
                      marginRight: "10px",
                    }}
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default Step3;
