import React, { useState, useEffect } from "react";
import styled from "styled-components";
import BTable from "react-bootstrap/Table";
import useQuery from "./containers/useQuery";
import TablePagination from "./pagination";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
} from "react-table";
import { useSticky } from "react-table-sticky";
import {
  GlobalFilter,
  DefaultColumnFilter,
  fuzzyTextFilterFn,
} from "./filters";
import TableHeaderComponent from "./containers/header";
import TableBodyComponent from "./containers/body";
import "./styles.css";
const Styles = styled.div`
  padding: 1rem;

  width: 100vw;

  table {
    /* border-spacing: 0; */
    border: 1px solid lightgrey;
    background-color: #fff;
    border-radius: 50px;
    /* border-top: 1px solid #000; */
    width: 100%;

    th {
      color: #7450be;
    }

    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 0px solid black;
      border-right: 0px solid black;
      /* background-color: #000; */

      width: calc(100% / columnCount);
      :last-child {
        border-right: 0;
      }
    }

    text-align: left;
  }
`;

fuzzyTextFilterFn.autoRemove = (val) => !val;
const Table = ({
  columns,
  data,
  actions,
  type,
  totalPages,

  onSave,
}) => {
  // const query = useQuery();
  const [borderColor, setBorderColor] = useState("#7450BE");

  const filterTypes = React.useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      autoResetFilters: false,
      autoResetSortBy: false,
      columns,
      data,
      defaultColumn,
      filterTypes,
    },

    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useSticky
  );
  return (
    <div className="!pt-3 !px-2 !bg-[#fff] rounded ">
      <BTable responsive hover size="sm" {...getTableProps()}>
        <thead>
          <tr>
            <th
              colSpan={visibleColumns.length + 3}
              id="table-global-filter"
              style={{
                textAlign: "left",
                borderTop: `3px solid ${borderColor}`,
              }}
            >
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
                onFocus={() => {
                  setBorderColor("#808080");
                }}
                onBlur={() => {
                  setBorderColor("#808080");
                }}
              />
            </th>
          </tr>
          {headerGroups.map((headerGroup) => (
            <TableHeaderComponent headerGroup={headerGroup} />
          ))}
        </thead>
        <TableBodyComponent
          getTableBodyProps={getTableBodyProps}
          page={page}
          prepareRow={prepareRow}
        />
      </BTable>
      <br />
      {/* <TablePagination
        gotoPage={gotoPage}
        previousPage={previousPage}
        nextPage={nextPage}
        canPreviousPage={
          totalPages > 1
            ? parseInt(query?.get("page") - 1) !== 0
            : canPreviousPage
        }
        canNextPage={
          totalPages > 1
            ? parseInt(query?.get("page") - 1) !== totalPages - 1
            : canNextPage
        }
        pageIndex={
          totalPages > 1
            ? query?.get("page")
              ? parseInt(query?.get("page") - 1)
              : 0
            : pageIndex
        }
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageCount={pageCount}
        pageOptions={pageOptions}
      /> */}

      <TablePagination
        nextPage={nextPage}
        previousPage={previousPage}
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        pageIndex={pageIndex}
        gotoPage={gotoPage}
        pageOptions={pageOptions}
        pageCount={pageCount}
      />
      {/* <div className="py-2 d-flex justify-content-between"> */}
      {/* {onSave && (
          <SimpleButton
            title="Save"
            className="float-right"
            color={"info"}
            type="button"
            style={{
              width: "130px",
              height: "35px",
              marginBottom: 2,
              marginLeft: 10,
            }}
            onClick={onSave}
          />
        )} */}
      {/* </div> */}
    </div>
  );
};

const CustomTable = ({
  columns = [],
  data = [],
  actions,
  totalPages,
  sortMethod,
  setPage,
  page,
  showPagination = true,
  onSave,
}) => {
  return (
    <Styles columnCount={columns.length}>
      <Table
        columns={columns}
        data={data}
        totalPages={totalPages}
        sortMethod={sortMethod}
        setPage={setPage}
        currentPage={page}
        showPagination={showPagination}
        onSave={onSave}
      />
    </Styles>
  );
};

export default CustomTable;
