import * as Yup from "yup";

// have_children: false,
//     no_of_children: 0,
//     children: [],

const step4Validations = Yup.object().shape({
  // have_children: Yup.boolean().required("This field is required"),
  no_of_children: Yup.number().required("This field is required"),
  children: Yup.array().when("no_of_children", {
    is: (val) => val > 0,
    then: () =>
      Yup.array().of(
        Yup.object().shape({
          first_name: Yup.string().required("This field is required"),
          middle_name: Yup.string(),
          last_name: Yup.string().required("This field is required"),
          phone: Yup.string(),
          email: Yup.string(),
          gender: Yup.string().required("This field is required"),
          dob: Yup.date().required("This field is required"),
          address: Yup.object().shape({
            street: Yup.string().required("This field is required"),
            appartment: Yup.string(),
            city: Yup.string().required("This field is required"),
            state: Yup.string().required("This field is required"),
            zipcode: Yup.string().required("This field is required"),
          }),
        })
      ),
    // Yup.array(),
    otherwise: () => Yup.array(),
  }),
});

export default step4Validations;
