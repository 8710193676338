import React, { useEffect, useState } from "react";
import "./styles.css";
import Button from "../../components/buttton";
import SelectInput from "../../components/selectInput";
import usePostQuery from "../../hooks/postQuery.hook";
import apiUrls from "../../apis/apis";
import Loader from "../../components/loader";
import { toast } from "react-toastify";
const adultSizes = [
  {
    label: "S (34-36)",
    value: "S (34-36)",
  },
  {
    label: "M (38-40)",
    value: "M (38-40)",
  },
  {
    label: "L (42-44)",
    value: "L (42-44)",
  },
  {
    label: "XL (46-48)",
    value: "XL (46-48)",
  },
  {
    label: "XXL (50-52)",
    value: "XXL (50-52)",
  },
  {
    label: "XXXL (54-56)",
    value: "XXXL (54-56)",
  },
];
const youthSizes = [
  {
    label: "S (6-8)",
    value: "S (6-8)",
  },
  {
    label: "M (10-12)",
    value: "M (10-12)",
  },
  {
    label: "L (14-16)",
    value: "L (14-16)",
  },
];

const TShirtSizeCard = ({ person, index, fetchUserData }) => {
  const [size, setSize] = useState();
  const [commingtoConvention, setCommingtoConvention] = useState("");
  useEffect(() => {
    if (person.tshirt_size) {
      setSize(person.tshirt_size);
    }
    if (person.comming_to_convention) {
      setCommingtoConvention(person.comming_to_convention);
    }
    if (person.size_type) {
      setSizeType(person.size_type);
    }
  }, [person.tshirt_size]);
  const { postQuery, loading } = usePostQuery();
  const [sizeType, setSizeType] = useState("");

  const handleSize = async (size) => {
    await postQuery({
      url: apiUrls.updateTshirtSize,
      postData: {
        _id: person?._id,
        tshirt_size: size,
        comming_to_convention: commingtoConvention,
        size_type: sizeType,
      },
      onSuccess: (result) => {
        console.log(result);
        fetchUserData();
      },
      onFail: (err) => {
        console.log(err);
      },
    });
  };

  // useEffect(() => {
  //   handleSize(person._id);
  // }, [size]);
  return (
    <div className="tShirtSizeCard">
      <Loader loading={loading} />
      {index + 1}. {person.first_name}
      <br />
      {"Coming to KPS Convention 2024?"}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          margin: "0.5rem 0",
        }}
      >
        <Button
          title="Yes"
          onClick={() => {
            setCommingtoConvention("Yes");
          }}
          style={{
            width: 100,
            background: commingtoConvention === "Yes" ? "#515fed" : "white",
            color: commingtoConvention === "Yes" ? "white" : "#515fed",
          }}
          showArrow={false}
        />
        <Button
          title="No"
          onClick={() => {
            setCommingtoConvention("No");
          }}
          style={{
            width: 100,
            background: commingtoConvention === "No" ? "#515fed" : "white",
            color: commingtoConvention === "No" ? "white" : "#515fed",
          }}
          showArrow={false}
        />
      </div>
      {"T-Shirt Size"}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          margin: "0.5rem 0",
        }}
      >
        <Button
          title="Adult"
          onClick={() => {
            setSizeType("Adult");
          }}
          style={{
            width: 100,
            background: sizeType === "Adult" ? "#515fed" : "white",
            color: sizeType === "Adult" ? "white" : "#515fed",
          }}
          showArrow={false}
          disabled={commingtoConvention === ""}
        />
        <Button
          title="Youth"
          onClick={() => {
            setSizeType("Youth");
          }}
          style={{
            width: 100,
            background: sizeType === "Youth" ? "#515fed" : "white",
            color: sizeType === "Youth" ? "white" : "#515fed",
          }}
          showArrow={false}
          disabled={commingtoConvention === ""}
        />
      </div>{" "}
      <SelectInput
        label={"Select an option based on Adult/Youth"}
        disabled={commingtoConvention === ""}
        value={size}
        options={
          sizeType === "Adult"
            ? adultSizes
            : sizeType === "Youth"
            ? youthSizes
            : []
        }
        onChange={(size) => {
          if (!commingtoConvention) {
            toast.error(
              "You need to select whether this person is joining for KPS Convention 2024 Cruise or not. Please select Yes or No"
            );
            return;
          }
          handleSize(size);
          setSize(size);
        }}
      />
    </div>
  );
};

export default TShirtSizeCard;
