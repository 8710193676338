import React, { useEffect, useState } from "react";
import "./style.css";

const SelectInput = ({
  label,
  error = "",
  name,
  value,
  options = [],
  onChange = () => {},
  className = "",
  disabled = false,
  ...props
}) => {
  const [selectedValue, setSelectedValue] = useState("");

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);
  const handleSelectChange = (event) => {
    const { value } = event.target;
    setSelectedValue(value);
    onChange(value);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <label htmlFor={name} className="small mobile-font">
        {label}
      </label>
      <select
        disabled={disabled}
        className={`col-12 px-3 py-2 border rounded mobile-font ${className}`}
        value={selectedValue}
        onChange={handleSelectChange}
      >
        <option value="">Select an option</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <span
        style={{
          height: "10px",
        }}
        className="mt-2 text-danger mobile-font"
      >
        {error}
      </span>
    </div>
  );
};

export default SelectInput;
