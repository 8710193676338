import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { RouterProvider } from "react-router-dom";
import Routes from "./routes";
import "./styles/commonStyles.css";
import "./styles/fontStyles.css";
import { Provider } from "react-redux";
import { store } from "./redux/store";

import ErrorBoundry from "./components/errorBoundry";

function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <ToastContainer
          position="top-right"
          autoClose={4000}
          hideProgressBar
          closeOnClick
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <ErrorBoundry>
          <Routes />
        </ErrorBoundry>
      </div>
    </Provider>
  );
}

export default App;
