import { Circle } from "react-bootstrap-icons";

const NumberOfChildern = ({
  value,
  onChange,
  error,
  label,
  required,
  name,
}) => {
  const childernOptions = [
    { value: 0, label: "0" },
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
  ];
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <label htmlFor={name} className="small">
          {label}
          {required && <span className="text-danger">*</span>}
        </label>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
            marginTop: "10px",
          }}
        >
          {childernOptions.map((item) => {
            return (
              <a
                onClick={() => {
                  onChange(item.value);
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "40px",
                    height: "40px",
                    border: "1px solid #7450BE",
                    borderRadius: "5px",
                    backgroundColor:
                      value === item.value
                        ? "#7450BE"
                        : "rgba(116, 80, 190, 0.05)",
                    color: value === item.value ? "#fff" : "#000",
                    marginRight: "10px",
                  }}
                >
                  {item.label}
                </div>
              </a>
            );
          })}
        </div>
        <span
          style={{
            fontSize: "12px",
            height: "10px",
          }}
          className="mt-2 text-danger"
        >
          {error}
        </span>
      </div>
    </>
  );
};

export default NumberOfChildern;
